import { useCallback, useMemo } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import CREEDDialogTitle from '../../CREEDDialogTitle';
import LoadingButton from '../../LoadingButton';
import { useGetEquityDealReviewForDealQuery, usePublishEquityDealMutation } from '../../../features/deals-api';
import { IDeal } from '../../../schemas';
import { useAppDispatch } from '../../../app/hooks';
import { snacked } from '../../../features/snackMessage-slice';
import EquityFeeStructureTableSponsorBroker from '../../Dialogs/EquityFeeStructureTableSponsor';
import AnticipatedEquityFeeAmountTable from '../../Dialogs/AnticipatedEquityFeeAmountTable';
import { useGetCurrentAccountQuery } from '../../../features/accounts-api';
// import EquityFeeStructureTableBroker from '../../Dialogs/EquityFeeStructureTableBroker';


interface Props extends DialogProps {
  readonly deal?: IDeal;
}

export default function PublishEquityDealDialog({deal, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  // const [bps, setBps] = useState('');

  const {data: account} = useGetCurrentAccountQuery();
  const {data: dealReview} = useGetEquityDealReviewForDealQuery({ dealId: deal?._id! }, { skip: deal?._id == null });
  
  const [publishDeal, { isLoading: isPublishDealLoading }] = usePublishEquityDealMutation();

  const mode = useMemo(() => {
    switch (dealReview?.status) {
      case 'approved':
        return 'approved';
      default:
        return 'agree-fee';
    }
  }, [dealReview?.status]);

  const publishClicked = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (deal == null) {
      return;
    }

    try {
      switch (account?.type) {
        case 'sponsor':
          await publishDeal({
            dealId: deal._id!,
            published: true,
          }).unwrap();
          break;
        case 'broker':
          await publishDeal({
            dealId: deal._id!,
            published: true,
          }).unwrap();
          break;
        default:
          break;
      }

      if (dialogProps.onClose != null) {
        dialogProps.onClose(e, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed publishing project.',
        severity: 'error',
      }));
    }
  }, [deal, account?.type, dialogProps, publishDeal, dispatch]);

  return (
    <Dialog
      fullWidth
      maxWidth={mode === 'agree-fee' ? 'lg' : 'sm'}
      {...dialogProps}>
      <CREEDDialogTitle
        title={mode === 'agree-fee' ? 'Anticipated Fee Structure' : 'Publish Project'}
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <form onSubmit={publishClicked}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}>
          {mode === 'agree-fee' ? (
            <>
              <DialogContentText>
                {'We are excited to help you with your project.'}
              </DialogContentText>
              <DialogContentText>
                {account?.type === 'broker' ? (
                  'To the extent an investor responds, you will have 6-month exclusivity with that investor for this deal.'
                ) : (
                  'To the extent an investor responds, CREED will have 6-month exclusivity with that investor for this deal.'
                )}
              </DialogContentText>
              <DialogContentText>
              {account?.type === 'broker' ? (
                  'Your client will only be charged a fee on a success basis at closing. CREED\'s fee would only be applicable as well if the deal closes. CREED\'s success fee for this project would be as follows:'
                ) : (
                  'You will only be charged a fee on a success basis at closing. The estimated success fee for this project would be as follows:'
                )}
              </DialogContentText>

              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '32px',
                paddingTop: '16px',
                paddingBottom: '16px',
              }}>
                {account?.type === 'sponsor' ? (
                  <>
                    <EquityFeeStructureTableSponsorBroker
                      feeStructure={account?.equityFeeStructure}
                      accountType={account?.type} />
                    
                    <AnticipatedEquityFeeAmountTable
                      amount={deal?.dealInfo?.loanAmount}
                      feeStructure={account?.equityFeeStructure}
                      accountType={account?.type} />
                  </>
                ) : account?.type === 'broker' ? (
                  <>
                    <EquityFeeStructureTableSponsorBroker
                      feeStructure={account?.equityFeeStructure}
                      accountType={account?.type} />
                    
                    <AnticipatedEquityFeeAmountTable
                      amount={deal?.dealInfo?.loanAmount}
                      feeStructure={account?.equityFeeStructure}
                      accountType={account?.type} />
                  </>
                ) : (
                  <></>
                )}
              </Box>
              </>
            ) : (
              <DialogContentText>
                {'Your project will be visible to investors on the Equity Marketplace.'}
              </DialogContentText>
            )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              flex: 1,
            }}
            onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
            {'Cancel'}
          </Button>
          <LoadingButton
            sx={{
              flex: 1,
            }}
            type='submit'
            variant='contained'
            loading={isPublishDealLoading}>
            {mode === 'agree-fee' ? 'Agree and Publish' : 'Publish'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
