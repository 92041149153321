import { useCallback, useEffect, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { loggedOut, loggedIn } from '../features/auth-slice';
import { setFinishedLoading } from '../features/loading-slice';
import { log } from '../utils/jsUtils';
import { clearChatState } from '../features/messaging-slice';
import { useGetCurrentAccountQuery } from '../features/accounts-api';


interface Props {
  element: () => JSX.Element;
}

/**
 * A react-router-dom route that automatically redirects to login
 * when visited by a user that does not have a valid session
 */
export default function ProtectedRoute({element: Element}: Props): JSX.Element {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const shouldBeLoggedIn = useAppSelector((state) => state.auth.shouldBeLoggedIn);

  const {data: account} = useGetCurrentAccountQuery();

  const validUnapprovedLocations = useMemo(() => {
    return [
      '/organization',
      '/profile',
      '/account-pending',
    ];
  }, []);

  const validInvalidSubscriptionLocations = useMemo(() => {
    return [
      '/organization',
      '/profile',
      '/team',
    ];
  }, []);

  const logout = useCallback(async() => {
    await Auth.signOut({ global: true });
    dispatch(clearChatState());
    dispatch(loggedOut());
    navigate('/login');
  }, [navigate, dispatch]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const { attributes } = user;
        const { email } = attributes;
        
        dispatch(loggedIn(email));
      } catch (e) {
        log('user logged out: ', window.location.pathname);
        if (shouldBeLoggedIn) {
          logout();
        } else if (window.location.pathname !== '/login') {
          dispatch(loggedOut());
          navigate(`/login?redirect=${window.location.pathname}`);
        }
      } finally {
        dispatch(setFinishedLoading());
      }
    }

    getUser();
  }, [dispatch, navigate, logout, shouldBeLoggedIn]);

  useEffect(() => {
    if (account != null && account.status !== 'approved' && !validUnapprovedLocations.includes(location.pathname)) {
      if (!account.profileComplete) {
        navigate('/organization');
      } else {
        navigate('/account-pending');
      }
    } else if (account?.status === 'approved' && !account.currentSubscription?.isActive && !validInvalidSubscriptionLocations.includes(location.pathname)) {
      navigate('/choose-plan');
    }
  }, [navigate, validUnapprovedLocations, location.pathname, account, validInvalidSubscriptionLocations]);

  return (
    <Element />
  );
}
