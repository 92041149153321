import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField } from "@mui/material";


interface Props {
  sponsorName?: string;
  sponsorHeadquarters?: string;
  sponsorWebsite?: string;
  onSponsorNameChange?: (value: string) => void;
  onSponsorHeadquartersChange?: (value: string) => void;
  onSponsorWebsiteChange?: (value: string) => void;
}

export default function SponsorInformationTableBroker({sponsorName, sponsorHeadquarters, sponsorWebsite, onSponsorNameChange, onSponsorHeadquartersChange, onSponsorWebsiteChange}: Props): JSX.Element {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}>
      <Typography sx={{
        fontSize: '14px',
        padding: '8px',
        borderRadius: '8px',
        background: '#33333311',
      }}>
        {'Sponsor Information'}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{'Name *'}</TableCell>
              <TableCell>{'HQ *'}</TableCell>
              <TableCell>{'Website *'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  required
                  size='small'
                  value={sponsorName}
                  onChange={e => onSponsorNameChange ? onSponsorNameChange(e.target.value) : null} />
              </TableCell>
              <TableCell>
                <TextField
                  required
                  size='small'
                  value={sponsorHeadquarters}
                  onChange={e => onSponsorHeadquartersChange ? onSponsorHeadquartersChange(e.target.value) : null} />
              </TableCell>
              <TableCell>
                <TextField
                  required
                  size='small'
                  value={sponsorWebsite}
                  onChange={e => onSponsorWebsiteChange ? onSponsorWebsiteChange(e.target.value) : null} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
