import { Button, DialogContentText, IconButton, Menu, MenuItem } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import { useCallback, useState } from "react";
import TextDialog from "../Dialogs/TextDialog";
import LoadingButton from "../LoadingButton";
import { IDeal } from "../../schemas";
import { snacked } from "../../features/snackMessage-slice";
import { useAppDispatch } from "../../app/hooks";
import { usePopoverContext } from "../../utils/hooks";
import * as Sentry from '@sentry/react';
import { useGetDealStatusOptionsQuery, useUpdateDealMutation } from "../../features/deals-api";


interface Props {
  deal?: IDeal;
}

export default function DealSettingsButton({deal}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const {
    props: changeStatusDialogProps,
    showDialog: showChangeStatus,
    hideDialog: hideChangeStatus,
    currentData: changeStatusData,
  } = usePopoverContext<string>();

  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);

  const {data: dealStatusOptions} = useGetDealStatusOptionsQuery();

  const [updateDeal, { isLoading: isUpdateDealLoading }] = useUpdateDealMutation();

  const changeDealStatus = useCallback((status: string) => {
    showChangeStatus(status);
    setSettingsAnchorEl(null);
  }, [showChangeStatus]);

  const changeStatusClicked = useCallback(async (deal?: IDeal, value?: string) => {
    if (deal == null || value == null) {
      return;
    }

    try {
      await updateDeal({
        dealId: deal._id!,
        status: value as any,
      });

      dispatch(snacked({
        message: 'Status changed',
        severity: 'success',
      }));

      hideChangeStatus();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed updating project',
        severity: 'error',
      }));
    }
  }, [dispatch, hideChangeStatus, updateDeal]);

  return (
    <>
      <IconButton
        onClick={e => setSettingsAnchorEl(e.currentTarget)}>
        <SettingsIcon sx={{
          color: '#333333DD',
        }} />
      </IconButton>

      <Menu
        anchorEl={settingsAnchorEl}
        open={settingsAnchorEl != null}
        onClose={() => setSettingsAnchorEl(null)}>
        {(deal?.status === 'search' || deal?.status === 'lender-outreach') &&
          <MenuItem onClick={() => changeDealStatus('diligence')}>
            {'Set Status to In Diligence'}
          </MenuItem>
        }
        {deal?.status === 'diligence' && (deal?.contactedLenders?.length ?? 0) === 0 &&
          <MenuItem onClick={() => changeDealStatus('search')}>
            {'Set Status to Search'}
          </MenuItem>
        }
        {deal?.status === 'diligence' && (deal?.contactedLenders?.length ?? 0) > 0 &&
          <MenuItem onClick={() => changeDealStatus('lender-outreach')}>
            {'Set Status to Lender Outreach'}
          </MenuItem>
        }
      </Menu>

      <TextDialog
        {...changeStatusDialogProps}
        title='Change Project Status'
        customContent={(
          <DialogContentText>
            {`Are you sure you want to change the status of this project to ${dealStatusOptions?.find(o => o.slug === changeStatusData)?.name}?`}
          </DialogContentText>
        )}
        actions={({close}) => (
          <>
            <Button
              sx={{
                flex: 1,
              }}
              onClick={close}>
              {'Cancel'}
            </Button>
            <LoadingButton
              sx={{
                flex: 1,
              }}
              variant='contained'
              loading={isUpdateDealLoading}
              onClick={() => changeStatusData != null ? changeStatusClicked(deal, changeStatusData) : null}>
              {'Change Status'}
            </LoadingButton>
          </>
        )}>
      </TextDialog>
    </>
  );
}
