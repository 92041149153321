import { Box, Button, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { useLazyGetDealFileDownloadUrlQuery } from "../../../features/deals-api";
import { snacked } from "../../../features/snackMessage-slice";
import { downloadFileFromUrl } from "../../../utils/utils";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { IDeal, IFile } from "../../../schemas";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useDropzone } from "react-dropzone";


interface Props {
  type: 'text' | 'date' | 'file';
  deal?: IDeal;
  title: string;
  value?: string;
  url?: string;
  fileInfo?: {file: IFile, type: 'loan-application-date'};
  editing?: boolean;
  onChange?: (value: any) => void;
  onFileSelected?: (file: File) => void;
} 

export default function DealInfoRow({type, deal, title, value, url, fileInfo, editing, onChange, onFileSelected}: Props): JSX.Element {
  
  const dispatch = useAppDispatch();

  const [selectedFile, setSelectedFile] = useState<File | undefined | null>();

  const [getFileDownloadUrl, { isLoading: isGetFileDownloadUrlLoading }] = useLazyGetDealFileDownloadUrlQuery();

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      if (onChange != null) {
        onChange(undefined);
      }

      if (onFileSelected != null) {
        onFileSelected(file);
      }
    },
  });

  const formattedValue = useMemo(() => {
    if (value == null) {
      return undefined;
    }
    
    switch (type) {
      case 'text':
      case 'file':
        return value;
      case 'date':
        return dayjs(value).format('MM/DD/YYYY');
    }
  }, [type, value]);

  const deleteFileClicked = useCallback(() => {
    setSelectedFile(null);

    if (onChange != null) {
      onChange(null);
    }
  }, [onChange]);

  const downloadFileClicked = useCallback(async () => {
    if (deal?._id == null || fileInfo == null) {
      return;
    }
    
    try {
      const result = await getFileDownloadUrl({
        dealId: deal._id,
        fileType: fileInfo.type,
        fileKey: fileInfo.file.key,
      }).unwrap();

      downloadFileFromUrl(result.url, fileInfo.file.name);
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed downloading file',
        severity: 'error',
      }));
    }
  }, [deal?._id, dispatch, fileInfo, getFileDownloadUrl]);

  useEffect(() => {
    if (!editing) {
      setSelectedFile(undefined);
    }
  }, [editing]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: type !== 'file' ? 'center' : 'flex-start',
      gap: 1,
    }}>
      <Typography sx={{
        display: 'flex',
        flex: 1,
        fontWeight: '500',
        fontSize: '14px',
        color: '#33333380',
      }}>
        {title}
      </Typography>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}>
        {editing ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'flex-start',
            gap: 1,
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}>
              {type === 'text' ? (
                <Typography>
                  {value}
                </Typography>
              ) : type === 'date' ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: false,
                        InputProps: {
                          sx: {
                            borderRadius: '8px',
                          }
                        }
                      }
                    }}
                    value={value != null ? dayjs(value) : null}
                    onChange={(d: Dayjs | null) => onChange ? onChange(d?.toISOString()) : null} />
                </LocalizationProvider>
              ) : type === 'file' ? (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: 1,
                    justifyContent: 'flex-end',
                  }}>
                    <Tooltip
                      title={`Upload ${title.toLowerCase()}`}
                      enterDelay={500}>
                      <Button
                        sx={{
                          flex: 1,
                          width: '100%',
                          gap: 1,
                          
                        }}
                        variant='outlined'
                        color='secondary'
                        size='small'
                        {...getRootProps()}>
                        <input {...getInputProps()} />
                        <FileUploadOutlinedIcon fontSize='inherit' />
                        {'Select File'}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={`Delete ${title.toLowerCase()}`}
                      enterDelay={500}>
                      <IconButton
                        color='warning'
                        disabled={value == null}
                        onClick={deleteFileClicked}>
                        <DeleteOutlineRoundedIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  
                  {/* {selectedFile !== undefined &&  */}
                    <Typography sx={{
                      fontWeight: '400',
                      fontSize: '12px',
                      fontStyle: 'italic',
                      textAlign: 'right',
                    }}
                    color={selectedFile === null ? 'red' : undefined}>
                      {selectedFile === null ? `${title} will be deleted` : (selectedFile?.name ?? formattedValue)}
                    </Typography>
                  {/* } */}
                </Box>
              ) : (<></>)}
            </Box>
          </Box>
        ) : (
          <>
            {fileInfo != null &&
              <Tooltip
                title={`Download ${title.toLowerCase()} file`}
                enterDelay={500}>
                <IconButton
                  size='small'
                  disabled={isGetFileDownloadUrlLoading}
                  onClick={downloadFileClicked}>
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            }
            {url != null ? (
              <Link
                sx={{
                  display: 'flex',
                  textAlign: 'right',
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#333333',
                  textDecorationColor: '#33333388',
                  '&:hover': {
                    textDecorationColor: '#333333',
                  }
                }}
                href={url}
                target='_blank'
                rel='noreferrer'>
                {formattedValue}
              </Link>
            ) : (
              <Typography sx={{
                display: 'flex',
                textAlign: 'right',
                fontWeight: '400',
                fontSize: '14px',
                color: '#333333',
              }}>
                {formattedValue}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
