import { Box, Button, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import AnticipatedEquityFeeAmountTable from "../AnticipatedEquityFeeAmountTable";
import AnticipatedLoanFeeAmountTable from "../AnticipatedLoanFeeAmountTable";
import EquityFeeStructureTableSponsorBroker from "../EquityFeeStructureTableSponsor";
import LoanFeeStructureTableSponsor from "../LoanFeeStructureTableSponsor";
import { IDeal } from "../../../schemas";
import LoadingButton from "../../LoadingButton";
import { useCallback } from "react";
import { useUpdateDealMutation } from "../../../features/deals-api";
import { useGetCurrentAccountQuery } from "../../../features/accounts-api";


interface Props {
  deal?: IDeal;
  onClose?: () => void;
  onComplete?: () => void;
}

export default function WhiteGloveContentSponsorDebt({deal, onClose, onComplete}: Props): JSX.Element {

  const {data: account} = useGetCurrentAccountQuery();

  const [updateDeal, { isLoading: isUpdateDealLoading }] = useUpdateDealMutation();

  const agreeClicked = useCallback(async () => {
    if (deal?._id == null) {
      return;
    }

    try {
      await updateDeal({
        dealId: deal._id,
        whiteGlove: {
          status: 'enabled',
        },
      }).unwrap();

      if (onComplete != null) {
        onComplete();
      }
    } catch (error) {
      console.error(error);
    }
  }, [deal?._id, onComplete, updateDeal]);

  return (
    <>
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}>
        <DialogContentText>
          {'We are excited to help you with your deal.'}
        </DialogContentText>
        <DialogContentText>
          {'Once CREED\'s White Glove Service is activated, our team of industry experts will help guide your deal from start to finish, including: '}
          <ol>
            <li>{'Review of material describing your project.'}</li>
            <li>{'Ensuring information is presented clearly and transparently to ensure productive lender feedback.'}</li>
            <li>{'Advocate for your deal with the lender community.'}</li>
            <li>{'Ensure all correspondence and dialogue is handled timely.'}</li>
            <li>{'Organize lender feedback in a clear way.'}</li>
          </ol>
        </DialogContentText>
        <DialogContentText>
          {'You could contact us anytime, via phone, video or messaging. You can also track the status of lender outreach using CREED\'s proprietary tracking portal.'}
        </DialogContentText>
        <DialogContentText>
          {'You will only be charged a fee on a success basis at closing. The estimated success fee for this project would be as follows:'}
        </DialogContentText>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '32px',
          paddingTop: '16px',
          paddingBlock: '16px',
        }}>
          {(deal?.dealInfo?.loanType?.optionName === 'Mezzanine' || deal?.dealInfo?.loanType?.optionName === 'Pref Equity') ? (
            <>
              <EquityFeeStructureTableSponsorBroker
                title={deal.dealInfo?.loanType?.optionName === 'Mezzanine' ? 'CREED Mezzanine Fee Structure' : 'CREED Pref Equity Fee Structure'}
                feeStructure={account?.equityFeeStructure} />

              <AnticipatedEquityFeeAmountTable
                amount={deal?.dealInfo?.loanAmount}
                feeStructure={account?.equityFeeStructure} />
            </>
          ) : (
            <>
              <LoanFeeStructureTableSponsor />
              <AnticipatedLoanFeeAmountTable amount={deal?.dealInfo?.loanAmount} />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={e => onClose ? onClose() : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isUpdateDealLoading}
          onClick={agreeClicked}>
          {'Agree'}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
