import DealParameterFormControl from './DealParameterFormControl';
import { DealParameterProps } from './DealParameter';
import { useDealParameter } from '../utils/hooks';
import { TextField } from '@mui/material';


export default function DealParameterText({field, formValues, dealInfo, error, setFormValue}: DealParameterProps): JSX.Element {
  
  const {
    title,
    disabled,
    dependingFieldTitles,
  } = useDealParameter({ field, dealInfo });
  
  return (
    <DealParameterFormControl
      field={field}
      disabled={disabled}
      dependingFieldTitles={dependingFieldTitles}>
      <TextField
        InputProps={{
          style: {
            borderRadius: '8px',
          }
        }}
        id={field.id}
        label={title}
        required={field.required}
        disabled={disabled}
        value={formValues[field.id] ?? ''}
        error={error != null}
        helperText={error}
        onChange={e => setFormValue(e.target.value)} />
    </DealParameterFormControl>
  );
}
