import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography } from "@mui/material";
import { useEquityFeeStructure } from "../../utils/hooks";
import { AccountEquityFeeStructure, AccountType } from "../../schemas";


interface Props {
  title?: string;
  feeStructure?: AccountEquityFeeStructure;
  accountType?: AccountType;
}

export default function EquityFeeStructureTableSponsorBroker({title, feeStructure, accountType}: Props): JSX.Element {

  const { tiers } = useEquityFeeStructure(undefined, feeStructure);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}>
      <Typography sx={{
        fontSize: '14px',
        padding: '8px',
        borderRadius: '8px',
        background: '#33333311',
      }}>
        {title ?? 'CREED Equity Fee Structure'}
      </Typography>

      {(feeStructure ?? 'legacy') === 'legacy' ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{'Tier'}</TableCell>
                <TableCell>{'Financing Amount Range'}</TableCell>
                <TableCell>{'Fee Amount'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tiers.map(tier => (
                <TableRow key={tier.name}>
                  <TableCell>{tier.name}</TableCell>
                  <TableCell>{tier.range}</TableCell>
                  <TableCell>{tier.feeAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : feeStructure === 'simple' ? (
        <Box sx={{
          paddingTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}>
          <Typography sx={{
            fontWeight: 500,
            fontSize: '22px',
          }}>
            {accountType === 'sponsor' ? 'Fixed 2%' : accountType === 'broker' ? 'Fixed 0.25%' : '-'}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
