import { useMemo } from "react";
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField } from "@mui/material";
import { IDeal } from "../../schemas";
import { formatNumericString } from "../../utils/utils";


interface Props {
  deal?: IDeal;
  bps?: string;
  onBpsChange?: (value: string) => void;
}

export default function LoanFeeStructureTableBroker({deal, bps, onBpsChange}: Props): JSX.Element {

  const feeAmount = useMemo(() => {
    if (deal?.dealInfo?.loanAmount == null || bps == null || bps.length === 0) {
      return undefined;
    }

    return Math.floor(deal.dealInfo.loanAmount * (parseFloat(bps) * 0.0001 * 0.5));
  }, [bps, deal?.dealInfo?.loanAmount]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}>
      <Typography sx={{
        fontSize: '14px',
        padding: '8px',
        borderRadius: '8px',
        background: '#33333311',
      }}>
        {'Anticipated Fee Amount'}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{'Anticipated Financing Amount'}</TableCell>
              <TableCell>{'Basis Point Fee *'}</TableCell>
              <TableCell>{'Dollar Fee Amount'}</TableCell>
              <TableCell>{'CREED Fee Amount'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{deal?.dealInfo?.loanAmount != null ? `$${formatNumericString(deal.dealInfo.loanAmount.toString())}` : '-'}</TableCell>
              <TableCell>
                <TextField
                  required
                  size='small'
                  type='number'
                  value={bps}
                  onChange={e => onBpsChange ? onBpsChange(e.target.value) : null} />
              </TableCell>
              <TableCell>{feeAmount != null ? `$${formatNumericString(feeAmount.toString())}` : '-'}</TableCell>
              <TableCell>{feeAmount != null ? `$${formatNumericString(feeAmount.toString())}` : '-'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
