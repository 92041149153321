import React from 'react';
import { IProfileField } from '../types/ProfileFields';
import { Box, Typography, Switch, TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


interface Props {
  readonly field: IProfileField;
  readonly values: Record<string, any>;
  readonly disabled?: boolean;
  readonly valueChanged?: (field: IProfileField, value: any) => void;
}

export default function OrganizationProfileField({field, values, disabled, valueChanged}: Props): JSX.Element {
  switch (field.type) {
    case 'boolean':
      return (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 1,
          paddingBottom: 1,
        }}>
          <Typography>
            {field.title}
          </Typography>

          <Switch
            inputProps={{ 'aria-label': 'controlled' }}
            color='primary'
            checked={!!values[field.key]}
            disabled={disabled}
            onChange={e => valueChanged ? valueChanged(field, e.target.checked) : null} />
        </Box>
      );
    case 'phone':
      return (
        <MuiTelInput
          InputProps={{
            style: {
              borderRadius: '8px',
            }
          }}
          label={field.title}
          required={field.required}
          fullWidth
          defaultCountry={'US'}
          onlyCountries={['US']}
          disableDropdown
          disabled={disabled}
          value={values[field.key] ?? ''}
          onChange={value => valueChanged ? valueChanged(field, value) : null} />
      );
    case 'select':
      return (
        <FormControl required={field.required}>
          <InputLabel id={field.key}>{field.title}</InputLabel>
          <Select
            sx={{
              borderRadius: '8px',
            }}
            labelId={field.key}
            label={field.title}
            fullWidth
            required={field.required}
            multiple={field.multiselect}
            value={values[field.key] ?? (field.multiselect ? [] : '')}
            renderValue={(selected) => selected.join(', ')}
            disabled={disabled}
            onChange={e => valueChanged ? valueChanged(field, e.target.value.filter((x: any) => x != null)) : null}>
            {field.multiselect &&
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
                
              }}>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  fontStyle: 'italic',
                  color: '#333333AA',
                }}>
                  {'Select all that apply'}
                </Typography>
              </Box>
            }
            {field.options?.map(option => (
              <MenuItem
                key={option}
                value={option}>
                <CheckRoundedIcon sx={{
                  fontSize: '18px',
                  color: '#333333AA',
                  opacity: (values[field.key] ?? []).includes(option) ? 1.0 : 0.0,
                }} />
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case 'radio':
      return (
        <FormControl required={field.required}>
          <FormLabel>{field.title}</FormLabel>
          <RadioGroup
            row
            value={values[field.key] ?? (field.radioOptions as any)[0]?.value}
            onChange={(e, value) => valueChanged ? valueChanged(field, value) : null}>
            {field.radioOptions?.map(option => (
              <FormControlLabel
                key={option.label}
                value={option.value}
                label={option.label}
                control={<Radio />} />
            ))}
          </RadioGroup>
        </FormControl>
      );
    case 'row':
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
        }}>
          {field.children?.map(child => (
            (child.isVisible == null || child.isVisible(values)) &&
              <OrganizationProfileField
                key={child.key}
                field={child}
                values={values}
                valueChanged={valueChanged} />
          ))}
        </Box>
      );
    default:
      return (
        <TextField
          InputProps={{
            style: {
              borderRadius: '8px',
            }
          }}
          label={field.title}
          required={field.required}
          multiline={field.multiline}
          fullWidth
          value={values[field.key] ?? ''}
          type={field.type === 'number' && field.formatting !== 'comma-separated' ? 'number' : 'text'}
          onChange={e => valueChanged ? valueChanged(field, e.target.value) : null}
          error={field.characterLimit != null && (values[field.key] as string)?.length > field.characterLimit}
          disabled={disabled}
          inputProps={{
            maxLength: field.characterLimit,
          }} />
      );
  }
}
