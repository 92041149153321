import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { createGoogleMapsUrl, formatNumericString, uploadFileToUrl } from "../../../utils/utils";
import Card from "../../Card";
import DealInfoRow from "./DealInfoRow";
import { IDeal, IDealDiligence } from "../../../schemas";
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { useCallback, useEffect, useState } from "react";
import { useGetCurrentAccountQuery } from "../../../features/accounts-api";
import { useCreateDealFileUploadUrlMutation, useUpdateDiligenceMutation } from "../../../features/deals-api";
import LoadingButton from "../../LoadingButton";
import * as Sentry from '@sentry/react';
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";


interface Props {
  deal?: IDeal;
}

export default function DiligenceDealInfoCard({deal}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [diligence, setDiligence] = useState<Pick<IDealDiligence, 'loanApplicationDt' | 'targetClosingDt' | 'loanApplicationDtFile'>>({});
  const [loanApplicationDateFile, setLoanApplicationDateFile] = useState<File>();

  const {data: account} = useGetCurrentAccountQuery();

  const [updateDiligence] = useUpdateDiligenceMutation();
  const [createFileUploadUrl] = useCreateDealFileUploadUrlMutation();

  const canEditDiligence = account?.isAdmin || (deal?.whiteGlove?.status !== 'enabled' && (account?.type === 'sponsor' || account?.type === 'broker'));

  const valueChanged = useCallback((key: keyof typeof diligence, value: any) => {
    setDiligence({
      ...diligence,
      [key]: value ?? null,
    });
  }, [diligence]);

  useEffect(() => {
    console.log(JSON.stringify(diligence, null, 2));
  }, [diligence]);

  const cancelClicked = useCallback(() => {
    setEditing(false);
  }, []);

  const saveClicked = useCallback(async () => {
    if (deal?._id == null) {
      return;
    }

    setLoading(true);
    
    try {
      await updateDiligence({
        dealId: deal._id,
        diligence: {
          loanApplicationDt: diligence.loanApplicationDt,
          targetClosingDt: diligence.targetClosingDt,
          loanApplicationDtFile: diligence.loanApplicationDtFile === null ? null : undefined,
        }
      });

      if (loanApplicationDateFile != null) {
        const result = await createFileUploadUrl({
          dealId: deal._id,
          fileType: 'diligence-loan-application-date',
          fileName: loanApplicationDateFile.name,
          contentType: loanApplicationDateFile.type,
        }).unwrap();

        await uploadFileToUrl(result.url, loanApplicationDateFile);
      }

      dispatch(snacked({
        message: 'Saved project info',
        severity: 'success',
      }));

      setEditing(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed saving project info',
        severity: 'error',
      }));
    } finally {
      setLoading(false);
    }
  }, [createFileUploadUrl, deal?._id, diligence.loanApplicationDt, diligence.loanApplicationDtFile, diligence.targetClosingDt, dispatch, loanApplicationDateFile, updateDiligence]);

  useEffect(() => {
    if (!editing) {
      setDiligence({
        loanApplicationDt: deal?.diligence?.loanApplicationDt,
        targetClosingDt: deal?.diligence?.targetClosingDt,
        loanApplicationDtFile: deal?.diligence?.loanApplicationDtFile,
      });

      setLoanApplicationDateFile(undefined);
    }
  }, [deal?.diligence?.loanApplicationDt, deal?.diligence?.loanApplicationDtFile, deal?.diligence?.targetClosingDt, editing]);

  return (
    <Card sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '16px',
        }}>
          {'Project Info'}
        </Typography>
        {canEditDiligence && !editing &&
          <Tooltip
            title='Edit project info'
            enterDelay={500}>
            <IconButton
              size='small'
              disabled={editing}
              onClick={() => setEditing(true)}>
              <ModeEditRoundedIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        }
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}>
        <DealInfoRow type='text' title='Property Address' value={deal?.dealInfo?.propertyCity} url={createGoogleMapsUrl(deal?.dealInfo?.propertyCity ?? '#', deal?.addressComponents?.zipcode)} />
        <DealInfoRow type='text' title='Loan Amount' value={`$${formatNumericString(deal?.dealInfo?.loanAmount?.toString())}`} />
        
        <DealInfoRow
          type='date'
          deal={deal}
          title='Loan Application Date'
          value={diligence.loanApplicationDt}
          fileInfo={deal?.diligence?.loanApplicationDtFile != null ? {file: deal.diligence.loanApplicationDtFile, type: 'loan-application-date'} : undefined}
          editing={editing}
          onChange={value => valueChanged('loanApplicationDt', value)} />

        {editing &&
          <DealInfoRow
            type='file'
            deal={deal}
            title='Loan Application Date File'
            value={diligence.loanApplicationDtFile?.name}
            editing={editing}
            onChange={value => value === null ? valueChanged('loanApplicationDtFile', null) : null}
            onFileSelected={file => setLoanApplicationDateFile(file)} />
        }

        <DealInfoRow
          type='date'
          title='Target Closing Date'
          value={diligence.targetClosingDt}
          editing={editing}
          onChange={value => valueChanged('targetClosingDt', value)} />

        {editing &&
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 2,
            gap: 1,
          }}>
            <Button
              sx={{
                flex: 1,
              }}
              onClick={cancelClicked}>
              {'Cancel'}
            </Button>
            <LoadingButton
              sx={{
                flex: 1,
              }}
              variant='contained'
              loading={loading}
              onClick={saveClicked}>
              {'Save Changes'}
            </LoadingButton>
          </Box>
        }
      </Box>
    </Card>
  )
}
