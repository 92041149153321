import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PageContainer from "../PageContainer";
import LoadingButton from "../LoadingButton";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useLocation, useNavigate } from "react-router-dom";
import { useCheckoutMutation } from "../../features/payments-api";
import * as Sentry from '@sentry/react';
import { useAppDispatch } from "../../app/hooks";
import { snacked } from "../../features/snackMessage-slice";
import { useGetCurrentAccountQuery } from "../../features/accounts-api";
import { brokerSubscriptionFeatures } from "../../types/SubscriptionFeatures";


export default function ChoosePlan(): JSX.Element {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [basicPlanLoading, setBasicPlanLoading] = useState(false);
  const [proPlanLoading, setProPlanLoading] = useState(false);

  const {data: account} = useGetCurrentAccountQuery();

  const [checkout] = useCheckoutMutation();

  const purchaseClicked = useCallback(async (product: 'broker-subscription-basic' | 'broker-subscription-pro') => {
    try {
      switch (product) {
        case 'broker-subscription-basic':
          setBasicPlanLoading(true);
          break;
        case 'broker-subscription-pro':
          setProPlanLoading(true);
          break;
      }

      const result = await checkout({
        product: product,
        cancelPath: location.pathname,
        redirectPath: '/home',
      }).unwrap();
      window.location.assign(result.url);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(snacked({
        message: 'Failed getting checkout url',
        severity: 'error',
      }))
    } finally {
      setBasicPlanLoading(false);
      setProPlanLoading(false);
    }
  }, [checkout, dispatch, location.pathname]);

  useEffect(() => {
    if (account == null) {
      return;
    }
    
    const eject = account?.type !== 'broker' || account?.currentSubscription?.isActive;
    if (eject) {
      navigate('/home');
    }
  }, [account, account?.currentSubscription?.isActive, account?.type, navigate]);

  return (
    <PageContainer showHeader={true}>
      <Typography sx={{
        fontSize: '64px',
      }}>
        {'Compare our features'}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 12,
        gap: '8px',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
        }}>
          <Box sx={{
            flex: 2,
          }} />

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            flex: 1,
            alignItems: 'center',
            paddingTop: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingBottom: '24px',
            borderRadius: '8px',
            background: 'linear-gradient(135deg, rgb(221, 207, 178), rgb(217, 179, 97))',
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '20px',
            }}>
              {'Basic'}
            </Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '40px',
                lineHeight: 1,
              }}>
                {'$49'}
              </Typography>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '18px',
                opacity: 0.6,
                color: 'rgb(51, 51, 51)',
              }}>
                {'/month'}
              </Typography>
            </Box>
            <LoadingButton
              sx={{
                width: '100%',
              }}
              variant='contained'
              color='secondary'
              disabled={account == null}
              loading={basicPlanLoading}
              onClick={() => purchaseClicked('broker-subscription-basic')}>
              {'Choose Plan'}
            </LoadingButton>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            flex: 1,
            alignItems: 'center',
            paddingTop: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingBottom: '24px',
            borderRadius: '8px',
            background: 'linear-gradient(135deg, rgb(221, 207, 178), rgb(217, 179, 97))',
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '20px',
            }}>
              {'Professional'}
            </Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '40px',
                lineHeight: 1,
              }}>
                {'$299'}
              </Typography>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '18px',
                opacity: 0.6,
                color: 'rgb(51, 51, 51)',
              }}>
                {'/month'}
              </Typography>
            </Box>
            <LoadingButton
              sx={{
                width: '100%',
              }}
              variant='contained'
              color='secondary'
              loading={proPlanLoading}
              disabled={account == null}
              onClick={() => purchaseClicked('broker-subscription-pro')}>
              {'Choose Plan'}
            </LoadingButton>
          </Box>
        </Box>
        <Box>
          {brokerSubscriptionFeatures.map((line, index) => (
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',

              paddingTop: '16px',
              paddingBottom: '16px',
              
              ...index < brokerSubscriptionFeatures.length - 1 ? {
                borderBottomWidth: '1px',
                borderBottomColor: 'rgba(51, 51, 51, 0.12)',
                borderBottomStyle: 'solid',
              } : {}
            }}>
              <Box sx={{
                display: 'flex',
                flex: 2,
                // background: 'red',
              }}>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '18px',
                }}>
                  {line.feature}
                </Typography>
              </Box>
              
              <Box sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                paddingRight: '12px',
              }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  borderRadius: '20px',
                  background: line.basic ? 'rgba(67, 151, 117, 0.2)' : 'rgba(51, 51, 51, 0.12)',
                }}>
                  {line.basic ? (
                    <CheckIcon sx={{
                      fontSize: '16px',
                      color: 'rgb(67, 151, 117)',
                    }} />
                  ) : (
                    <ClearIcon sx={{
                      fontSize: '16px',
                      color: 'rgb(31, 31, 31)',
                    }} />
                  )}
                </Box>
              </Box>

              <Box sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                paddingLeft: '12px',
                // background: 'green',
              }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  borderRadius: '20px',
                  background: line.pro ? 'rgba(67, 151, 117, 0.2)' : 'rgba(51, 51, 51, 0.12)',
                }}>
                  {line.pro ? (
                    <CheckIcon sx={{
                      fontSize: '16px',
                      color: 'rgb(67, 151, 117)',
                    }} />
                  ) : (
                    <ClearIcon sx={{
                      fontSize: '16px',
                      color: 'rgb(31, 31, 31)',
                    }} />
                  )}
                </Box>
              </Box>
            </Box>
          ))}  
        </Box>
      </Box>
    </PageContainer>
  );
}
