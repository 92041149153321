import React, { useCallback, useMemo, useState } from 'react';
import { Dialog, DialogTitle, Typography, DialogContent, Box, TextField, Divider, List, DialogProps } from '@mui/material';
import { IAccount, IDeal } from '../../../schemas';
import { useGetDealInvitationsQuery, useGetDealParticipantsQuery, useGetSharedDealsForDealQuery, useInviteUsersToDealMutation } from '../../../features/deals-api';
import { useAppDispatch } from '../../../app/hooks';
import { snacked } from '../../../features/snackMessage-slice';
import sxClasses from '../../Styles/DealInvitationsDialog.Styles';
import useCustomClassesAndTheme from '../../useCustomClassesAndTheme';
import { useGetCurrentAccountQuery } from '../../../features/accounts-api';
import LoadingButton from '../../LoadingButton';
import DialogCloseButton from '../../DialogCloseButton';
import DealAccessListItem from './DealAccessListItem';


interface Props extends DialogProps {
  readonly deal?: IDeal;
}

export default function DealAccessDialog({deal, ...dialogProps}: Props): JSX.Element {

  const classes = useCustomClassesAndTheme(sxClasses);
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');

  const {data: account} = useGetCurrentAccountQuery();
  const {data: dealParticipants} = useGetDealParticipantsQuery({ dealId: deal?._id ?? ''}, { skip: deal == null, pollingInterval: 3000 });
  const {data: invitations} = useGetDealInvitationsQuery(deal?._id!, { skip: deal == null || deal.accountId !== account?._id, pollingInterval: 3000 });
  const {data: sharedDeals} = useGetSharedDealsForDealQuery({ dealId: deal?._id!, accountType: 'all' }, { skip: deal == null || deal.accountId !== account?._id, pollingInterval: 3000 });

  const [inviteUsersToDeal, { isLoading: isInviteUsersLoading }] = useInviteUsersToDealMutation();

  const dealParticipantMap: Record<string, IAccount> = useMemo(() => {
    return (dealParticipants ?? []).reduce((obj, account) => {
      return {
        ...obj,
        [account._id]: account,
      };
    }, {});
  }, [dealParticipants]);

  const inviteClicked = useCallback(async () => {
    if (deal?._id == null || !email) {
      return;
    }

    try {
      await inviteUsersToDeal({
        dealId: deal._id,
        emails: [email],
      }).unwrap();

      setEmail('');
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failing inviting user',
        severity: 'error',
      }));
    }
  }, [deal?._id, email, inviteUsersToDeal, dispatch]);

  return (
    <Dialog
      {...dialogProps}
      sx={classes.dialog}>
      <DialogTitle sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Box>
          <Typography sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px',
          }}>
            {account?.type === 'broker' ? (
              'Invite lender'
            ) : (
              'Manage access'
            )}
          </Typography>
          
          <Typography sx={{
            marginTop: '4px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17px',
            color: '#333333BF',
          }}>
            {'Invite stakeholders to review or collaborate on this deal.'}
          </Typography>
        </Box>

        <DialogCloseButton onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      </DialogTitle>
      <DialogContent sx={{
        padding: 0,
        paddingBottom: '16px',
      }}>
        <Divider />

        <Box sx={{
          padding: '24px',
        }}>
          <Typography sx={{
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '19.57px',
          }}>
            {'Invite new stakeholders'}
          </Typography>

          <Box 
            sx={{
              marginTop: '8px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '8px',
              background: '#3333330A',
            }}
            display='flex'
            alignItems='center'>
            <TextField
              sx={{
                flex: 1,
                minWidth: '40%',
                fontWeight: '400',
                fontSize: '14px',
                '& fieldset': { border: 'none' },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Enter an email'
              disabled={isInviteUsersLoading || !account?.currentSubscription?.isActive} />

            <LoadingButton
              variant='contained'
              color='secondary'
              onClick={inviteClicked}
              loading={isInviteUsersLoading}
              disabled={!account?.currentSubscription?.isActive || email.length === 0}>
              {'Send invite'}
            </LoadingButton>
          </Box>
        </Box>

        {((sharedDeals?.length ?? 0) > 0 || (invitations?.length ?? 0) > 0) &&
          <Divider variant='middle' />
        }

        <List sx={{
          paddingTop: '12px',
        }}>
          {sharedDeals?.map(sharedDeal => (
            <DealAccessListItem
              key={sharedDeal._id}
              deal={deal}
              account={dealParticipantMap[sharedDeal.accountId]}
              sharedDeal={sharedDeal} />
          ))}
        </List>

        {invitations != null && invitations.length > 0 &&
          <Box sx={{
            paddingTop: '12px',
          }}>
            <Typography sx={{
              marginLeft: '24px',
              fontWeight: '500',
              fontSize: '14px',
              color: '#333333DD'
            }}>
              {'Pending Invitations'}
            </Typography>

            <List>
              {invitations?.filter(i => i.status === 'pending')?.map(invitation => (
                <DealAccessListItem
                  key={invitation._id}
                  deal={deal}
                  account={invitation.acceptingAccountId != null ? dealParticipantMap[invitation.acceptingAccountId] : undefined}
                  invitation={invitation} />
              ))}
            </List>
          </Box>
        }
      </DialogContent>
    </Dialog>
  );
}
