import { DialogContent, DialogContentText, DialogProps } from "@mui/material";
import { IDeal } from "../../../schemas";
import { useGetCurrentAccountQuery } from "../../../features/accounts-api";
import WhiteGloveContentSponsorDebt from "./WhiteGloveContentSponsorDebt";
import WhiteGloveContentBrokerDebt from "./WhiteGloveContentBrokerDebt";


interface Props {
  deal?: IDeal;
  dialogProps: DialogProps;
  setState: (state: 'info' | 'complete') => void;
}

export function WhiteGloveInfoContent({deal, dialogProps, setState}: Props): JSX.Element {

  const {data: account} = useGetCurrentAccountQuery();

  switch (account?.type) {
    case 'sponsor':
      return (
        <WhiteGloveContentSponsorDebt
          deal={deal}
          onClose={() => dialogProps.onClose ? dialogProps.onClose({}, 'escapeKeyDown') : null}
          onComplete={() => setState('complete')} />
      );
    case 'broker':
      return (
        <WhiteGloveContentBrokerDebt
          deal={deal}
          onClose={() => dialogProps.onClose ? dialogProps.onClose({}, 'escapeKeyDown') : null}
          onComplete={() => dialogProps.onClose ? dialogProps.onClose({}, 'escapeKeyDown') : null} />
      );
    default:
      return (
        <DialogContent>
          <DialogContentText>
            {'Unhandled account type!'}
          </DialogContentText>
        </DialogContent>
      );
  }
}
