import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { formatNumericString } from "../../utils/utils";
import { useEquityFeeStructure } from "../../utils/hooks";
import { AccountEquityFeeStructure, AccountType } from "../../schemas";


interface Props {
  amount?: number;
  feeStructure?: AccountEquityFeeStructure;
  accountType?: AccountType;
}

export default function AnticipatedEquityFeeAmountTable({amount, feeStructure, accountType}: Props): JSX.Element {
  
  const {
    dollarFeeAmount,
    blendedBasisPointFeeAmount,
  } = useEquityFeeStructure(amount, feeStructure, accountType);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}>
      <Typography sx={{
        fontSize: '14px',
        padding: '8px',
        borderRadius: '8px',
        background: '#33333311',
      }}>
        {'Anticipated Fee Amount'}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{'Anticipated Equity Amount'}</TableCell>
              <TableCell>{'Blended Basis Point Fee Amount'}</TableCell>
              <TableCell>{'Dollar Fee Amount'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{`$${formatNumericString(amount?.toFixed(0))}`}</TableCell>
              <TableCell>{`${blendedBasisPointFeeAmount?.toFixed(0)} bps`}</TableCell>
              <TableCell>{`$${formatNumericString(dollarFeeAmount?.toFixed(0))}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
