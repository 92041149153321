import React, { PropsWithChildren, useEffect } from 'react';
import { Box, Grid, CircularProgress, SxProps, Theme } from '@mui/material';
import sxClasses from './Styles/PageContainer.Styles';
import Header, { HeaderProps } from './Header';
import useCustomClassesAndTheme from './useCustomClassesAndTheme';
import SnackMessage from './SnackMessage';
import Chat from './Chat';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { closeChat } from '../features/messaging-slice';
import { useGetCurrentAccountQuery } from '../features/accounts-api';
import ScrollToTop from '../ScrollToTop';
import { useLocation } from 'react-router-dom';


type Props = PropsWithChildren<{
  readonly loading?: boolean;
  readonly showHeader?: boolean;
  readonly headerProps?: HeaderProps;
  readonly contentSx?: SxProps<Theme>;
}>;

export default function PageContainer(props: Props) {
  const classes = useCustomClassesAndTheme(sxClasses);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const chatState = useAppSelector(state => state.chat);

  const {data: account} = useGetCurrentAccountQuery();

  useEffect(() => {
    if (chatState.windows != null && chatState.windows.length > 0) {
      for (const window of chatState.windows) {
        if (window.requiredPath != null && window.requiredPath !== location.pathname) {
          dispatch(closeChat({ key: window.key }));
        }
      }
    }
  }, [chatState.windows, dispatch, location.pathname]);

  return (
    <div style={classes.root}>
      <ScrollToTop />
      {props.showHeader !== false &&
        <>
          <Header {...props.headerProps} />
        </>
      }

      <Grid container sx={{
        display: 'flex',
        width: '100%',
        paddingTop: '50px',
      }}>
        <Grid item xs />

        <Grid item xs sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Box sx={{
            ...classes.childBox,
            paddingTop: account?.currentSubscription?.isActive ? 0 : '30px',
            ...props.contentSx ?? {},
          }}>
            {props.loading ? (
              <Box sx={classes.loadingIndicatorBox}>
                <CircularProgress />
              </Box>
            ) : (
              props.children
            )}
          </Box>
        </Grid>

        <Grid item xs={true} sx={{
          display: 'flex',
          position: 'relative',
          paddingTop: '16px',
          height: '100vh',
        }} />
      </Grid>

      {chatState.windows.map((chatWindow, index) => (
        <Chat
          key={chatWindow.key}
          sx={{
            position: 'fixed',
            width: '400px',
            bottom: 0,
            right: `calc(60px + ${index} * 460px)`,
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            zIndex: 100,
          }}
          windowKey={chatWindow.key}
          recipientAccountIds={chatWindow.recipientAccountIds}
          dealId={chatWindow.dealId}
          defaultMessage={chatWindow.defaultMessage}
          identifier={chatWindow.threadIdentifier}
          initialSendAction={chatWindow.initialSendAction}
          threadId={chatWindow.threadId}
          title={chatWindow.title}
          subtitle={chatWindow.subtitle}
          closable={chatWindow.closable}
          requiredPath={chatWindow.requiredPath}
          minimizable={chatWindow.minimizable}
          minimized={chatWindow.minimized}
          onClose={() => dispatch(closeChat({ key: chatWindow.key }))} />
      ))}

      <SnackMessage />
    </div>
  );
}
