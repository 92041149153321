import React from 'react';
import { IDeal } from '../../../schemas';
import LenderSearchResultsDataGrid from './LenderSearchResultsDataGrid';
import LenderSearchFavoritesInfo from './LenderSearchFavoritesDataGrid';
import LenderSearchContactedDataGrid from './LenderSearchContactedDataGrid';
import LenderSearchQuoteMatrixDataGrid from './LenderSearchQuoteMatrixDataGrid';


interface Props {
  readonly filter: 'all' | 'favorites' | 'lender-outreach' | 'quote-matrix';
  readonly deal?: IDeal;
}

export default function LenderSearchInfo({filter, deal}: Props): JSX.Element {
  switch (filter) {
    case 'all':
      return (<LenderSearchResultsDataGrid deal={deal} />);
    case 'favorites':
      return (<LenderSearchFavoritesInfo deal={deal} />);
    case 'lender-outreach':
      return (<LenderSearchContactedDataGrid deal={deal} />);
    case 'quote-matrix':
      return (<LenderSearchQuoteMatrixDataGrid deal={deal} />);
  }
}
