import { useCallback, useMemo, useState } from "react";
import { formatNumericString, parseNumericString } from "../utils/utils";
import { useGetInterestRatesQuery } from "../features/utils-api";
import { IDealQuoteTerms } from "../schemas";


type FieldValues = Record<keyof IDealQuoteTerms, string>;

export interface DealQuoteTermField {
  readonly title: string;
  readonly key: keyof IDealQuoteTerms;
  readonly type: 'text' | 'number' | 'select' | 'computed';
  readonly options?: string[];
  readonly optionFormatter?: (option: string) => string;
  readonly compute?: (params: {values: FieldValues}) => string | undefined;
  readonly dependsOn?: (keyof IDealQuoteTerms)[];
  readonly required: boolean;
  readonly readonly?: boolean;
  readonly hiddenInMatrix?: boolean;
  readonly formatting?: 'comma-separated';
  readonly valueMetadata?: Record<string, any>;
  readonly prefix?: string;
  readonly suffix?: string;
  readonly multiline?: boolean;
  readonly min?: number;
  readonly max?: number;
}


function parseInterestRate(rate?: string) {
  if (rate == null) {
    return undefined;
  }

  return parseFloat(rate.replaceAll('%', ''));
}

const emptyFieldValues: FieldValues = {
  totalProceeds: '',
  loanTerm: '',
  extensionOptions: '',
  interestRateIndex: '',
  interestRateSpread: '',
  allInRate: '',
  exitFee: '',
  extensionFee: '',
  goodFaithDeposit: '',
  fixedFloating: '',
  originationFee: '',
  maxLTV: '',
  recourse: '',
  amortization: '',
  debtYieldMin: '',
  dscrMin: '',
  prepayment: '',
  lenderType: '',
  comments: '',
  totalFees: '',
  totalReserves: '',
  netProceeds: '',
};

export function useDealQuoteTermFields() {

  const [fieldValuesRaw, setFieldValuesRaw] = useState<FieldValues>(emptyFieldValues);

  const {data: interestRates} = useGetInterestRatesQuery();

  const usTreasuriesRates = useMemo(() => {
    return interestRates?.find(ir => ir.title === 'U.S. Treasuries');
  }, [interestRates]);

  const sofr = useMemo(() => {
    return interestRates?.find(ir => ir.title === 'Secured Overnight Financing Rate (SOFR)');
  }, [interestRates]);

  const otherUSRates = useMemo(() => {
    return interestRates?.find(ir => ir.title === 'Other U.S. Rates');
  }, [interestRates]);

  const fields: DealQuoteTermField[] = useMemo(() => {
    return [
      {
        title: 'Total Proceeds (USD)',
        key: 'totalProceeds',
        type: 'number',
        formatting: 'comma-separated',
        prefix: '$',
        required: false,
      },
      {
        title: 'Loan Term (Years)',
        key: 'loanTerm',
        type: 'number',
        required: false,
      },
      {
        title: 'Extension Options',
        key: 'extensionOptions',
        type: 'text',
        required: false,
      },
      {
        title: 'Interest Rate Index',
        key: 'interestRateIndex',
        type: 'select',
        required: false,
        readonly: sofr == null || usTreasuriesRates == null,
        options: [
          'Current SOFR',
          '30-Day Avg SOFR',
          '1-Month Term SOFR',
          '1 Year U.S. Treasury Rate',
          '2 Year U.S. Treasury Rate',
          '3 Year U.S. Treasury Rate',
          '5 Year U.S. Treasury Rate',
          '7 Year U.S. Treasury Rate',
          '10 Year U.S. Treasury Rate',
          'Prime',
        ],
        valueMetadata: {
          'Current SOFR': parseInterestRate(sofr?.rows[0][1]),
          '30-Day Avg SOFR': parseInterestRate(sofr?.rows[1][1]),
          '1-Month Term SOFR': parseInterestRate(sofr?.rows[3][1]),
          '1 Year U.S. Treasury Rate': parseInterestRate(usTreasuriesRates?.rows[0][1]),
          '2 Year U.S. Treasury Rate': parseInterestRate(usTreasuriesRates?.rows[1][1]),
          '3 Year U.S. Treasury Rate': parseInterestRate(usTreasuriesRates?.rows[2][1]),
          '5 Year U.S. Treasury Rate': parseInterestRate(usTreasuriesRates?.rows[3][1]),
          '7 Year U.S. Treasury Rate': parseInterestRate(usTreasuriesRates?.rows[4][1]),
          '10 Year U.S. Treasury Rate': parseInterestRate(usTreasuriesRates?.rows[5][1]),
          'Prime': parseInterestRate(otherUSRates?.rows[1][1]),
        },
        optionFormatter: (option: string) => {
          const field = fields.find(f => f.key === 'interestRateIndex');
          if (field == null || field.valueMetadata == null) {
            return option;
          }

          const rate: number | undefined = field.valueMetadata[option];
          if (rate == null) {
            return option;
          }

          return `${option} (${rate.toFixed(2)}% today)`;
        },
      },
      {
        title: 'Interest Rate Spread (%)',
        key: 'interestRateSpread',
        type: 'number',
        suffix: '%',
        min: 0,
        max: 100,
        required: false,
      },
      {
        title: 'All-in-rate (%)',
        key: 'allInRate',
        type: 'computed',
        dependsOn: ['interestRateIndex', 'interestRateSpread'],
        compute: ({values}) => {
          const indexField = fields.find(f => f.key === 'interestRateIndex');
          if (indexField == null || indexField.valueMetadata == null) {
            return undefined;
          }

          const indexValue = values.interestRateIndex;
          if (indexValue == null) {
            return undefined;
          }

          const index: number | undefined = indexField.valueMetadata[indexValue];
          if (index == null) {
            return undefined;
          }

          const spread = values.interestRateSpread != null ? parseFloat(`${values.interestRateSpread}`) : undefined;
          if (spread == null || isNaN(spread)) {
            return undefined;
          }
          
          return `${(index + spread).toFixed(2)}`;
        },
        readonly: true,
        required: false,
        suffix: '%',
        min: 0,
        max: 100,
      },
      {
        title: 'Origination Fee (%)',
        key: 'originationFee',
        type: 'number',
        suffix: '%',
        min: 0,
        max: 100,
        required: false,
      },
      {
        title: 'Exit Fee (%)',
        key: 'exitFee',
        type: 'number',
        suffix: '%',
        min: 0,
        max: 100,
        required: false,
      },
      {
        title: 'Good Faith Deposit (USD)',
        key: 'goodFaithDeposit',
        type: 'number',
        formatting: 'comma-separated',
        prefix: '$',
        required: false,
      },
      {
        title: 'Fixed/Floating',
        key: 'fixedFloating',
        type: 'select',
        options: ['Fixed', 'Floating', 'Fixed or Floating'],
        required: false,
      },
      {
        title: 'Max LTV/LTC (%)',
        key: 'maxLTV',
        type: 'text',
        required: false,
      },
      {
        title: 'Recourse',
        key: 'recourse',
        type: 'text',
        required: false,
      },
      {
        title: 'Amortization (Months)',
        key: 'amortization',
        type: 'text',
        required: false,
      },
      {
        title: 'Debt Yield Min (%)',
        key: 'debtYieldMin',
        type: 'number',
        suffix: '%',
        min: 0,
        max: 100,
        required: false,
      },
      {
        title: 'DSCR Min',
        key: 'dscrMin',
        type: 'text',
        required: false,
      },
      {
        title: 'Prepayment',
        key: 'prepayment',
        type: 'text',
        required: false,
      },
      {
        title: 'Lender Type',
        key: 'lenderType',
        type: 'text',
        required: false,
        hiddenInMatrix: true,
      },
      {
        title: 'Total Fees',
        key: 'totalFees',
        type: 'computed',
        dependsOn: ['totalProceeds', 'originationFee', 'exitFee'],
        readonly: true,
        required: false,
        prefix: '$',
        compute: ({values}) => {
          const parsedTotalProceeds = parseNumericString(values.totalProceeds);
          const parsedOriginationFee = parseFloat(values.originationFee) || 0.0;
          const parsedExitFee = parseFloat(values.exitFee) || 0.0;

          if (parsedTotalProceeds == null || isNaN(parsedTotalProceeds) || parsedOriginationFee == null || isNaN(parsedOriginationFee) || parsedExitFee == null || isNaN(parsedExitFee)) {
            return undefined;
          }

          const totalFees = ((parsedOriginationFee + parsedExitFee) / 100.0) * parsedTotalProceeds;
          return formatNumericString(totalFees.toFixed(0));
        },
      },
      {
        title: 'Total Reserves',
        key: 'totalReserves',
        type: 'number',
        formatting: 'comma-separated',
        readonly: false,
        required: false,
        prefix: '$',
      },
      {
        title: 'Net Proceeds',
        key: 'netProceeds',
        type: 'computed',
        dependsOn: ['totalProceeds', 'totalFees', 'totalReserves'],
        formatting: 'comma-separated',
        readonly: true,
        required: false,
        prefix: '$',
        compute: ({values}) => {
          const parsedTotalProceeds = parseNumericString(values.totalProceeds);
          const parsedTotalFees = parseNumericString(values.totalFees);
          let parsedTotalReserves = parseNumericString(values.totalReserves);

          if (parsedTotalProceeds == null || isNaN(parsedTotalProceeds) || parsedTotalFees == null || isNaN(parsedTotalFees)) {
            return undefined;
          }

          if (parsedTotalReserves == null || isNaN(parsedTotalReserves)) {
            parsedTotalReserves = 0;
          }

          const netProceeds = parsedTotalProceeds - (parsedTotalFees + parsedTotalReserves);

          return formatNumericString(netProceeds.toFixed(0));
        }
      },
      {
        title: 'Other Stips or Comments',
        key: 'comments',
        type: 'text',
        multiline: true,
        required: false,
      },
    ];
  }, [sofr, usTreasuriesRates, otherUSRates?.rows]);

  const convertDealTermToFieldValue = useCallback((termKey: keyof IDealQuoteTerms, termValue: any): string => {
    if (termValue == null) {
      return '';
    }
    
    const field = fields.find(f => f.key === termKey);
    if (field == null) {
      return '';
    }

    let formattedValue: string = termValue?.toString() ?? '';

    switch (field.type) {
      case 'text':
      case 'computed':
      case 'number':
        switch (field.formatting) {
          case 'comma-separated':
            formattedValue = formatNumericString(formattedValue) ?? '';
            break;
        }
        break;
    }

    return formattedValue;
  }, [fields]);

  const computeFieldValue = useCallback((field: DealQuoteTermField, values: FieldValues) => {
    if (field.type !== 'computed') {
      return values[field.key];
    }

    if (field.compute == null || field.dependsOn == null || field.dependsOn.length === 0) {
      throw new Error('Computed field is missing compute() function or dependsOn array!');
    }

    const terms: Record<string, any> = {...values};
    const dependentFields = fields.filter(f => field.dependsOn?.includes(f.key));
    for (const depField of dependentFields) {
      terms[depField.key] = computeFieldValue(depField, terms as any);
    }

    return field.compute({values: terms as any});
  }, [fields]);

  const setRawFieldValues = useCallback((values: IDealQuoteTerms) => {
    let newFieldValues: FieldValues = Object.keys(values)
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: convertDealTermToFieldValue(key as keyof IDealQuoteTerms, values[key as keyof IDealQuoteTerms]),
        };
      }, emptyFieldValues);

    const computedFields = fields.filter(f => f.type === 'computed');
    for (const compField of computedFields) {
      newFieldValues[compField.key] = computeFieldValue(compField, newFieldValues) as string;
    }

    setFieldValuesRaw(newFieldValues);
  }, [computeFieldValue, convertDealTermToFieldValue, fields]);

  const clearFieldValues = useCallback(() => {
    setFieldValuesRaw(emptyFieldValues);
  }, []);

  const onFieldValueChanged = useCallback((field: DealQuoteTermField, value: string) => {
    let formattedValue = value;
    switch (field.formatting) {
      case 'comma-separated':
        formattedValue = formatNumericString(`${value}`) ?? '';
        break;
    }

    let changes = {[field.key]: formattedValue};
    let dependentFields = fields.filter(f => f.type === 'computed' && f.dependsOn?.includes(field.key));
    while (dependentFields.length > 0) {
      let newDepFields: DealQuoteTermField[] = [];
      for (const depField of dependentFields) {
        if (depField.compute == null) {
          continue;
        }

        changes[depField.key] = depField.compute({values: {...fieldValuesRaw, ...changes}}) ?? '';
        
        newDepFields = [
          ...newDepFields,
          ...fields.filter(f => f.dependsOn?.includes(depField.key))
        ];
      }

      dependentFields = newDepFields;
    }

    setFieldValuesRaw({
      ...fieldValuesRaw,
      ...changes,
    });
  }, [fieldValuesRaw, fields]);

  const packageFieldValues = useCallback((): IDealQuoteTerms => {
    return Object.keys(fieldValuesRaw).reduce((obj, key) => {
      const field = fields.find(f => f.key === key);
      if (field == null || field.type === 'computed') {
        return obj;
      }

      const rawValue = fieldValuesRaw[key as keyof IDealQuoteTerms].trim();
      
      let finalValue: string | number | undefined = rawValue;
      if (rawValue == null || rawValue.length === 0) {
        finalValue = undefined;
      } else {
        switch (field.type) {
          case 'number': {
            const parsedValue = parseNumericString(rawValue);
            if (parsedValue != null) {
              finalValue = parsedValue;
            } else {
              finalValue = undefined;
            }
            break;
          }
          default:
            break;
        }
      }
      
      return {
        ...obj,
        [key]: finalValue,
      };
    }, {}) as IDealQuoteTerms;
  }, [fieldValuesRaw, fields]);

  return {
    fields: fields,
    fieldValues: fieldValuesRaw,
    computeFieldValue,
    setRawFieldValues,
    clearFieldValues,
    onFieldValueChanged,
    packageFieldValues,
  };
}
