import { Box, Button, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { IDeal } from "../../../schemas";
import LoanFeeStructureTableBroker from "../LoanFeeStructureTableBroker";
import SponsorInformationTableBroker from "../SponsorInformationTableBroker";
import LoadingButton from "../../LoadingButton";
import { useCallback, useState } from "react";
import { useUpdateDealMutation } from "../../../features/deals-api";


interface Props {
  deal?: IDeal;
  onClose?: () => void;
  onComplete?: () => void;
}

export default function WhiteGloveContentBrokerDebt({deal, onClose, onComplete}: Props): JSX.Element {

  const [bps, setBps] = useState('');
  const [sponsorName, setSponsorName] = useState('');
  const [sponsorHeadquarters, setSponsorHeadquarters] = useState('');
  const [sponsorWebsite, setSponsorWebsite] = useState('');

  const [updateDeal, { isLoading: isUpdateDealLoading }] = useUpdateDealMutation();

  const agreeClicked = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (deal?._id == null) {
      return;
    }

    const parsedBps = parseInt(bps);
    if (isNaN(parsedBps)) {
      console.error('Parsing BPS resulted in NaN');
      return;
    }

    try {
      await updateDeal({
        dealId: deal._id,
        whiteGlove: {
          status: 'requested',
          basisPointFee: parsedBps,
          sponsorInfo: {
            name: sponsorName,
            headquarters: sponsorHeadquarters,
            website: sponsorWebsite,
          }
        },
      }).unwrap();

      window.open(`https://calendar.google.com/calendar/appointments/AcZssZ3ycJgSWAWAOdsFjdO_T_8eo8Znjm_-3ok5HRs=?gv=true`, '_blank', 'noopener,noreferrer');

      if (onComplete != null) {
        onComplete();
      }
    } catch (error) {
      console.error(error);
    }
  }, [bps, deal?._id, onComplete, sponsorHeadquarters, sponsorName, sponsorWebsite, updateDeal]);


  return (
    <form onSubmit={agreeClicked}>
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}>
        <DialogContentText>
          {'We are excited to help you with your client\'s project'}
        </DialogContentText>

        <DialogContentText>
          <b>Non-Circumvention: </b>
          {`CREED agrees not to circumvent or attempt to circumvent the broker by directly or indirectly engaging with any sponsor or borrower introduced through the CREED's co-brokerage service. Should any transaction be closed with a sponsor or borrower introduced via CREED's co-brokerage service during the 36 month period following the engagement, the broker shall be deemed to be owed a fee as agreed via CREED's co-brokerage digital interface. Similarly, the broker agrees not to bypass, avoid, or exclude CREED with respect to any transaction for which CREED's co-brokerage service has been activated. CREED shall be deemed to be owed a fee as agreed via CREED's digital co-broker interface during any 12 month period following CREED's digital co-broker activation.`}
        </DialogContentText>

        <DialogContentText>
          {'You could contact us anytime, via phone, video or messaging. You can also track the status of lender outreach using CREED\'s proprietary tracking portal.'}
        </DialogContentText>
        <DialogContentText>
          {'You will only be charged a fee on a success basis at closing. The estimated success fee for this project would be as follows:'}
        </DialogContentText>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '32px',
          paddingTop: '16px',
          paddingBlock: '16px',
        }}>
          <LoanFeeStructureTableBroker
            deal={deal}
            bps={bps}
            onBpsChange={setBps} />

          <SponsorInformationTableBroker
            sponsorName={sponsorName}
            sponsorHeadquarters={sponsorHeadquarters}
            sponsorWebsite={sponsorWebsite}
            onSponsorNameChange={setSponsorName}
            onSponsorHeadquartersChange={setSponsorHeadquarters}
            onSponsorWebsiteChange={setSponsorWebsite} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={() => onClose ? onClose() : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          type='submit'
          variant='contained'
          loading={isUpdateDealLoading}>
          {'Agree and Book Appointment'}
        </LoadingButton>
      </DialogActions>
    </form>
  );
}
