import { Tooltip } from "@mui/material";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid";

export function wrapColumnsInTooltips<T extends GridValidRowModel>(columns: (GridColDef<T> | undefined)[]): GridColDef<T>[] {
  return (columns as GridColDef<T>[])
    .filter(c => c != null)
    .map(col => {
      if (col.type === 'actions' || col.renderCell != null) {
        return col;
      }

      return {
        ...col,
        renderCell: (params) => (
          <Tooltip
            title={params.formattedValue}
            enterDelay={1000}>
            <div style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
              {params.formattedValue}
            </div>
          </Tooltip>
        ),
      }
  });
}
