import React, { useContext } from 'react';
import { Box, Breadcrumbs, Link, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { IDeal } from '../../schemas';
import DealStatusChip from './DealStatusChip';
import { DealContext } from '../routes/Deal';
import ContactInfo from './ContactInfo';
import Card from '../Card';
import DealSettingsButton from './DealSettingsButton';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import { createGoogleMapsUrl } from '../../utils/utils';


interface Props {
  readonly deal?: IDeal;
  readonly manageAccessClicked?: () => void;
}

export default function DealDiligenceHeader({deal, manageAccessClicked}: Props): JSX.Element {

  const navigate = useNavigate();

  const {breadcrumbs} = useContext(DealContext);

  const {data: account} = useGetCurrentAccountQuery();

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '40px',
      }}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              sx={{color: '#333333'}}
              fontSize='small' />
          }
          aria-label='breadcrumb'>
          {breadcrumbs?.map((item, index) => (
            index < breadcrumbs.length - 1 && item.path != null ? (
              <Link
                key={item.path}
                sx={{
                  ':hover': {
                    cursor: 'pointer' 
                  }
                }}
                underline='hover'
                color='#33333380'
                onClick={() => item.path != null ? navigate(item.path) : null}>
                {item.title}
              </Link>
            ) : (
              <Typography
                key={`${index}`}
                color='#333333'>
                {item.title}
              </Typography>
            )
          ))}
        </Breadcrumbs>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
        }}>
          {manageAccessClicked != null &&
            <Button
              sx={{
                gap: '8px',
              }}
              variant='outlined'
              color='secondary'
              onClick={manageAccessClicked}>
              <PersonAddOutlinedIcon sx={{fontSize: '16px'}} />
              {account?.type === 'broker' ? (
                'Invite lender'
              ) : (
                'Manage access'
              )}
            </Button>
          }

          {deal?.accountId === account?._id
            && deal?.whiteGlove?.status !== 'enabled' &&
            <DealSettingsButton deal={deal} />
          }
        </Box>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <img
            style={{
              width: '120px',
              height: '120px',
              borderRadius: '8px',

              objectFit: 'cover',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            src={deal?.imageUrl ? deal.imageUrl : require('../../images/default-creed-deal.jpg')}
            alt={deal?.name ?? 'Project'} />

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            paddingLeft: '24px',
          }}>
            <DealStatusChip
              sx={{
                alignSelf: 'flex-start',
                marginBottom: '4px',
              }}
              status={deal?.status} />

            <Link
              sx={{
                fontWeight: '400',
                fontSize: '28px',
                lineHeight: '34.24px',
                color: '#333333',
                textDecorationColor: '#33333388',
                '&:hover': {
                  textDecorationColor: '#333333',
                }
              }}
              href={createGoogleMapsUrl(deal?.dealInfo?.propertyCity ?? '#', deal?.addressComponents?.zipcode)}
              target='_blank'
              rel='noreferrer'>
              {deal?.name ?? 'Project'}
            </Link>
          </Box>
        </Box>

        {(((account?.type === 'broker' || account?.type === 'lender') && deal?.whiteGlove?.status === 'enabled') || (account?.type !== 'broker' && account?.type !== 'lender')) &&
          <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 2,
            borderRadius: '8px',
            background: 'white',
          }}>
            <Typography sx={{
              fontWeight: '500',
              fontSize: '16px',
            }}>
              {'CREED Contacts'}
            </Typography>
            {deal?.diligence?.creedContacts != null && deal.diligence.creedContacts.length > 0 ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
              }}>
                {deal?.diligence?.creedContacts?.map(contact => (
                  <ContactInfo
                    sx={{
                      maxWidth: '150px',
                    }}
                    key={contact._id}
                    name={contact.name}
                    email={contact.email}
                    phone={contact.phone}
                    address={contact.address} />
                ))}
              </Box>
            ) : (
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                color: '#333333AA',
              }}>
                {'TBD'}
              </Typography>
            )}
          </Card>
        }
      </Box>
    </Box>
  );
}
