import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Avatar, Box, Chip, CircularProgress, Divider, IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { accountsApiSlice, useDeleteTearSheetMutation, useGetCurrentAccountQuery, useGetProfileImageUploadUrlMutation, useGetTearSheetUploadUrlMutation, useLazyGetTearSheetDownloadUrlQuery, useUpdateAccountProfileMutation } from '../../features/accounts-api';
import PageContainer from '../PageContainer';
import useCustomClassesAndTheme from '../useCustomClassesAndTheme';
import sxClasses from '../Styles/Profile.Styles';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { IProfileField, getProfileFields } from '../../types/ProfileFields';
import LoadingButton from '../LoadingButton';
import { dealsApiSlice } from '../../features/deals-api';
import OrganizationProfileField from '../OrganizationProfileField';
import { downloadFileFromUrl, formatNumericString, parseNumericString } from '../../utils/utils';
import { IAccountProfile, IFile } from '../../schemas';
import { useAccountProfileInsights, usePopoverContext } from '../../utils/hooks';
import { useNavigate } from 'react-router-dom';
import FileUploadBox from '../FileUploadBox';
import FileRow from '../FileRow';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PreviewFileDialog from '../Deal/PreviewFileDialog';
import * as Sentry from '@sentry/react';


interface FormState {
  readonly values: Record<string, any>;
}

enum FormActionType {
  SetValues,
}

interface FormActionSetValues {
  readonly type: FormActionType.SetValues;
  readonly values: Record<string, any>;
}

type FormAction = FormActionSetValues;

function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case FormActionType.SetValues:
      return {
        values: {
          ...state.values,
          ...action.values,
        }
      };
  }
}


export default function Organization() {
  const classes = useCustomClassesAndTheme(sxClasses);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tearSheetLoading, setTearSheetLoading] = useState(false);
  const [profileImageLoading, setProfileImageLoading] = useState(false);

  const {data: account, isFetching: isAccountFetching, refetch: refetchAccount} = useGetCurrentAccountQuery();
  const [getTearSheetUrl] = useLazyGetTearSheetDownloadUrlQuery();

  const [getProfileImageUploadUrl] = useGetProfileImageUploadUrlMutation();
  const [getTearSheetUploadUrl] = useGetTearSheetUploadUrlMutation();
  const [deleteTearSheet] = useDeleteTearSheetMutation();
  const [updateAccountProfile, { isLoading: isUpdateAccountProfileLoading }] = useUpdateAccountProfileMutation();

  const {isComplete} = useAccountProfileInsights(account);

  const [formState, formDispatch] = useReducer(formReducer, {values: {}});

  const {
    props: tearSheetMenuProps,
    showDialog: showTearSheetMenu,
    hideDialog: hideTearSheetMenu,
    currentData: selectedTearSheet,
  } = usePopoverContext<{file: IFile, target: HTMLElement}>();

  const {
    props: tearSheetPreviewDialogProps,
    showDialog: showTearSheetPreview,
    currentData: previewingTearSheet,
  } = usePopoverContext<{file: IFile, url: string}>();

  const {
    getInputProps: getProfileImageInputProps,
    open: openProfileImageDialog,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      if (account == null) {
        return;
      }

      const file = acceptedFiles[0];
      if (file == null) {
        return;
      }

      setProfileImageLoading(true);

      try {
        const urlResponse = await getProfileImageUploadUrl({
          fileName: file.name,
          contentType: file.type,
        }).unwrap();
  
        await axios.put(urlResponse.url, file, {
          headers: {
            'Content-Type': file.type
          },
        });

        setTimeout(async () => {
          await refetchAccount();
          dispatch(accountsApiSlice.util.invalidateTags(['User']));
          dispatch(dealsApiSlice.util.invalidateTags(['DealParticipant']));
          setProfileImageLoading(false);
        }, 500);
      } catch (error) {
        console.error(error);
        
        dispatch(snacked({
          message: 'Error uploading image',
          severity: 'error',
        }));
        
        setProfileImageLoading(false);
      }
    }
  });

  const sections = useMemo(() => {
    if (account?.type == null) {
      return [];
    }

    return getProfileFields(account.type);
  }, [account?.type]);

  const profileValueChanged = useCallback((field: IProfileField, value: any) => {
    switch (field.formatting) {
      case 'comma-separated':
        value = formatNumericString(value);
        break;
    }

    formDispatch({
      type: FormActionType.SetValues,
      values: {
        [field.key]: value,
      }
    });
  }, [formDispatch]);

  const profileImageClicked = useCallback(() => {
    if (account == null) {
      return;
    }

    openProfileImageDialog();
  }, [openProfileImageDialog, account]);

  const onTearSheetFilesDropped = useCallback(async (acceptedFiles: File[]) => {
    setTearSheetLoading(true);

    try {
      const uploadUrlResponses = await Promise.all(acceptedFiles.map(file => getTearSheetUploadUrl({
        fileName: file.name,
        contentType: file.type,
      }).unwrap()));

      await Promise.all(uploadUrlResponses.map((response, index) => {
        if (!response.url) {
          return null;
        }

        const file = acceptedFiles[index];
        return axios.put(response.url, file, {
          headers: {
            'Content-Type': file.type
          }
        });
      }));

      setTimeout(async () => {
        await refetchAccount();
        dispatch(accountsApiSlice.util.invalidateTags(['User']));
        dispatch(dealsApiSlice.util.invalidateTags(['DealParticipant']));
        setTearSheetLoading(false);
      }, 1000);
    } catch (error) {
      console.error(error);
      
      dispatch(snacked({
        message: 'Error uploading image',
        severity: 'error',
      }));

      setTearSheetLoading(false);
    }
  }, [setTearSheetLoading, dispatch, getTearSheetUploadUrl, refetchAccount]);

  const previewTearSheetClicked = useCallback(async (file: IFile) => {
    try {
      const response = await getTearSheetUrl({fileKey: file.key}).unwrap();
      showTearSheetPreview({file, url: response.url});
    } catch (error) {
      console.error(error);
      
      dispatch(snacked({
        message: 'Error previewing file',
        severity: 'error',
      }));
    }
  }, [dispatch, getTearSheetUrl, showTearSheetPreview]);

  const downloadTearSheetClicked = useCallback(async (file: IFile) => {
    const response = await getTearSheetUrl({fileKey: file.key}).unwrap();
    downloadFileFromUrl(response.url, file.name ?? file.key);
  }, [getTearSheetUrl]);

  const deleteTearSheetClicked = useCallback(() => {
    if (selectedTearSheet?.file == null) {
      return;
    }

    deleteTearSheet({fileKey: selectedTearSheet.file.key});
    hideTearSheetMenu();
  }, [selectedTearSheet?.file, deleteTearSheet, hideTearSheetMenu]);

  const saveChangesClicked = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const allFields: Record<string, IProfileField> = sections
      .flatMap(s => s.fields)
      .reduce((obj, field) => {
        const children = field.children?.reduce((obj, child) => {
          return {
            ...obj,
            [child.key]: child,
          };
        }, {}) ?? {};

        return {
          ...obj,
          [field.key]: field,
          ...children,
        };
      }, {});

    const keys = Object
      .keys(formState.values)
      .filter(k => formState.values[k] != null);

    const newProfile: Record<string, any> = keys.reduce((obj, key) => {
      const field = allFields[key];
      if (field == null) {
        return obj;
      }

      let value: any = formState.values[key];
      if (field.type === 'number') {
        value = parseNumericString(value);
        if (value == null || isNaN(value)) {
          value = undefined;
        }
      }

      return {
        ...obj,
        [key]: value,
      };
    }, {});

    try {
      await updateAccountProfile({profile: newProfile}).unwrap();

      if (!account?.profileComplete) {
        switch (account?.type) {
          case 'sponsor':
          case 'broker':
          case 'lender':
            if (account?.status === 'pending') {
              navigate('/account-pending');
            } else {
              navigate('/home');
            }
            break;
          case 'investor':
            if (account?.status === 'pending') {
              navigate('/account-pending');
            } else {
              navigate('/equity-deals');
            }
            break;
        }
      }

      dispatch(snacked({
        message: 'Organization profile saved',
        severity: 'success',
      }));
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed saving organization profile',
        severity: 'error',
      }))
    }
  }, [sections, formState.values, updateAccountProfile, account, navigate, dispatch]);

  useEffect(() => {
    if (account?.profile == null) {
      return;
    }

    const allFields: Record<string, IProfileField> = sections
      .flatMap(s => s.fields)
      .reduce((obj, field) => {
        const children = field.children?.reduce((obj, child) => {
          return {
            ...obj,
            [child.key]: child,
          };
        }, {}) ?? {};

        return {
          ...obj,
          [field.key]: field,
          ...children,
        };
      }, {});

    const values = Object.keys(allFields).reduce((obj, key) => {
      const field = allFields[key];
      let value = account?.profile[key as keyof IAccountProfile];
      if (field.type === 'number') {
        switch (field.formatting) {
          case 'comma-separated':
            value = value != null ? formatNumericString(`${value}`) : undefined;
            break;
        }
      }

      return {
        ...obj,
        [key]: value,
      };
    }, {});
    
    formDispatch({
      type: FormActionType.SetValues,
      values: values,
    });
  }, [account?.profile, sections]);

  return (
    <PageContainer
      contentSx={classes.pageContainerContent}
      loading={isAccountFetching && account == null}>
      <Box sx={classes.profileHeaderContainerBox}>
        <input {...getProfileImageInputProps()} />
        <Box sx={classes.profileImageContainerBox}>
          <Avatar
            sx={{
              width: '156px',
              height: '156px',
              background: '#3333330A',
              color: '#3333334D',
            }}
            src={account?.profileImageUrl} />

          {profileImageLoading &&
            <Box sx={classes.profileImageOverlayBox}>
              <CircularProgress
                sx={classes.profileImageLoadingIndicator}
                size={25} />
            </Box>
          }

          {account?.currentSubscription?.isActive &&
            <IconButton
              sx={{
                position: 'absolute',
                bottom: '4px',
                right: '4px',
                display: 'flex',
                width: '48px',
                height: '48px',
              }}
              color='primary'
              disabled={profileImageLoading}
              onClick={profileImageClicked}>
              <AddAPhotoIcon sx={{fontSize: '18px'}} />
            </IconButton>
          }
        </Box>

        <Box sx={{
          marginTop: '25px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px',
        }}>
          <Typography
            variant='h3'>
            {account?.name}
          </Typography>

          <Chip
            label={account?.type}
            size='small'
            variant='outlined' />
        </Box>
      </Box>

      <form onSubmit={saveChangesClicked}>
        {sections.map((section, sectionIndex) => (
          <Box key={sectionIndex} sx={classes.sectionContainerBox}>
            {section.fields.map(field => (
              (field.isVisible == null || field.isVisible(formState.values)) &&
                <Box
                  key={field.key}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '22px',
                    [theme.breakpoints.down('md')]: {
                      paddingLeft: 2,
                      paddingRight: 2,
                    }
                  }}>
                  {field.header &&
                    <Typography sx={{marginTop: '12px'}}>
                      {field.header}
                    </Typography>
                  }

                  <OrganizationProfileField
                    field={field}
                    values={formState.values}
                    valueChanged={profileValueChanged} />

                  {field.info &&
                    <Typography
                      sx={{paddingBottom: '6px', color: '#33333380'}}
                      variant='caption'>
                      {field.info}
                    </Typography>
                  }
                </Box>
            ))}

            {sectionIndex < sections.length - 1 &&
              <Divider sx={classes.fieldDivider} />
            }
          </Box>
        ))}

        {(account?.type === 'investor' || account?.type === 'lender') &&
          <Box sx={{
            paddingBottom: '32px',
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '17px',
              marginBottom: '16px',
            }}>
              {'Attach Tear Sheet(s)'}
            </Typography>

            <FileUploadBox
              onFilesDropped={onTearSheetFilesDropped}
              loading={tearSheetLoading} />

            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '16px',
            }}>
              {account?.tearSheets?.map(tearSheet => (
                <FileRow
                  key={tearSheet.key}
                  file={tearSheet}
                  variant='compact'
                  previewClicked={() => previewTearSheetClicked(tearSheet)}
                  downloadClicked={() => downloadTearSheetClicked(tearSheet)}
                  moreClicked={e => showTearSheetMenu({file: tearSheet, target: e.currentTarget})}
                  canDelete={true} />
              ))}
            </Box>
          </Box>
        }

        <LoadingButton
          sx={classes.saveButton}
          type='submit'
          variant={'contained'}
          loading={isUpdateAccountProfileLoading}>
          {isComplete ? 'Update Profile' : 'Save Profile'}
        </LoadingButton>
      </form>

      <Menu
        {...tearSheetMenuProps}
        anchorEl={selectedTearSheet?.target}>
        <MenuItem onClick={deleteTearSheetClicked}>
          <DeleteOutlinedIcon
            sx={{color: '#33333380'}}
            fontSize='small' />
          <Typography variant='menuItem'>
            {'Delete file'}
          </Typography>
        </MenuItem>
      </Menu>

      <PreviewFileDialog
        {...tearSheetPreviewDialogProps}
        title={previewingTearSheet?.file.name}
        fileUrl={previewingTearSheet?.url} />
    </PageContainer>
  );
}
