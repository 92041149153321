import React, { useContext } from 'react';
import { IDeal } from '../../schemas';
import { Box, Breadcrumbs, Button, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { useNavigate } from 'react-router-dom';
import { DealContext } from '../routes/Deal';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import { DealDialogContext } from './DealBase';
import { useCREEDScore } from '../../types/CREEDScoreFields';


interface Props {
  readonly deal?: IDeal;
}

export default function DealBaseHeader({deal}: Props): JSX.Element {
  
  const navigate = useNavigate();

  const {
    showCREEDScoreDialog,
    showDealAccessDialog,
    showSponsorProfileDialog,
  } = useContext(DealDialogContext);

  const {
    breadcrumbs
  } = useContext(DealContext);

  const {averageScore} = useCREEDScore(deal);

  const {data: account} = useGetCurrentAccountQuery();

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '40px',
      }}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              sx={{color: '#333333'}}
              fontSize='small' />
          }
          aria-label='breadcrumb'>
          {breadcrumbs?.map((item, index) => (
            index < breadcrumbs.length - 1 && item.path != null ? (
              <Link
                key={item.path}
                sx={{
                  ':hover': {
                    cursor: 'pointer' 
                  }
                }}
                underline='hover'
                color='#33333380'
                onClick={() => item.path != null ? navigate(item.path) : null}>
                {item.title}
              </Link>
            ) : (
              <Typography
                key={`${index}`}
                color='#333333'>
                {item.title}
              </Typography>
            )
          ))}
        </Breadcrumbs>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '32px',
        }}>
          {deal?.accountId === account?._id && account?.type !== 'broker' && showDealAccessDialog != null ? (
            <Button
              sx={{
                gap: '8px',
              }}
              variant='outlined'
              color='secondary'
              onClick={showDealAccessDialog}>
              <PersonAddOutlinedIcon sx={{fontSize: '16px'}} />
              {'Manage access'}
            </Button>
            
            // <>
            
            //   {deal?.dealInfo?.equityDebt?.optionName === 'Debt'
            //     && deal.whiteGlove?.status !== 'enabled'
            //     && (account?.type !== 'broker' || (account.currentSubscription?.isActive && account.currentSubscription.type === 'broker-pro')) &&
            //     <DealSettingsButton deal={deal} />
            //   }
            // </>
          ) : (
            (account?.type === 'lender' || account?.type === 'investor') &&
              <>
                {deal?.dealInfo?.equityDebt?.optionName === 'Debt' &&
                  <Button
                    sx={{
                      gap: '4px',
                    }}
                    variant='outlined'
                    onClick={e => showCREEDScoreDialog ? showCREEDScoreDialog(e.currentTarget) : null}>
                    <Typography sx={{
                      fontWeight: '500',
                      fontSize: '14px',
                    }}>
                      {averageScore != null ? `${averageScore.toFixed(0)}/100` : 'TBD'}
                    </Typography>
                    <Typography sx={{
                      fontWeight: '400',
                      fontSize: '14px',
                    }}>
                      {`CREED Score`}
                    </Typography>
                  </Button>
                }

                {showSponsorProfileDialog != null &&
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={showSponsorProfileDialog}>
                    {deal?.account?.type === 'sponsor' ? 'Sponsor Profile' : 'Broker Profile'}
                  </Button>
                }
              </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
