import { Box, Button, Card, Divider, FormControl, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetMarketplaceDealsQuery, useGetSearchOptionsQuery } from "../features/deals-api";
import EquityMarketplaceFilterGeographicFocusDialog from "./Dialogs/EquityMarketplaceFilterGeographicFocusDialog";
import { useEffectDebounced, usePopoverContext, usePrevious } from "../utils/hooks";
import { useGetCurrentAccountEquityMarketplaceFiltersQuery, useGetCurrentAccountQuery, useUpdateCurrentAccountEquityMarketplaceFiltersMutation, useUpdateCurrentAccountMutation } from "../features/accounts-api";
import { IAccountEquityMarketplaceFilters } from "../schemas";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';



export default function EquityMarketplaceFilters(): JSX.Element {

  const {
    props: geographicFocusDialogProps,
    showDialog: showGeographicFocusDialog,
  } = usePopoverContext();

  const [accountFilter, setAccountFilter] = useState<'all' | 'filtered'>('all');
  const [currentFilters, setCurrentFilters] = useState<Partial<Record<keyof IAccountEquityMarketplaceFilters, any>>>({
    equityTypes: [],
    states: [],
    cities: [],
    minCheckSizeMM: '',
    maxCheckSizeMM: '',
    propertyTypes: [],
    businessPlans: [],
    investorLevelIrr: '',
    yoc: '',
    missionDrivenStrategies: [],
    minHoldPeriod: '',
    maxHoldPeriod: '',
    broker: '',
    sponsorExclusivity: '',
  });
  
  const {data: account} = useGetCurrentAccountQuery();
  const {data: filters, isLoading: isFiltersLoading, isSuccess: isGetFiltersSuccess} = useGetCurrentAccountEquityMarketplaceFiltersQuery();
  const {refetch: refetchMarketplaceDeals} = useGetMarketplaceDealsQuery({type: 'all', filtered: true});
  const {data: propertyTypeChoices, isFetching: isPropertyTypeChoicesFetching} = useGetSearchOptionsQuery('propertyType');
  const {data: businessPlanChoices, isFetching: isBusinessPlanChoicesFetching} = useGetSearchOptionsQuery('businessPlan');
  const {data: missionDrivenStrategyChoices, isFetching: isMissionDrivenStrategyChoicesFetching} = useGetSearchOptionsQuery('otherEquity');

  const [updateCurrentAccount] = useUpdateCurrentAccountMutation();
  const [updateFilters] = useUpdateCurrentAccountEquityMarketplaceFiltersMutation();

  const previousFilters = usePrevious(filters);

  const equityTypes = useMemo(() => {
    return [
      {title: 'LP', value: 'LP Equity'},
      {title: 'JV', value: 'JV Equity'},
      {title: 'Co-GP', value: 'Co-GP Equity'},
      {title: 'Pref', value: 'Pref Equity'},
    ];
  }, []);

  const onAccountFilterChanged = useCallback((value: typeof accountFilter) => {
    setAccountFilter(value);
    updateCurrentAccount({
      equityMarketplaceFilter: value,
    });
  }, [updateCurrentAccount]);

  const updateFiltersAndRefetchDeals = useCallback(async () => {
    await updateFilters({
      equityTypes: currentFilters.equityTypes || [],
      states: currentFilters.states || [],
      cities: currentFilters.cities || [],
      minCheckSizeMM: parseFloat(currentFilters.minCheckSizeMM),
      maxCheckSizeMM: parseFloat(currentFilters.maxCheckSizeMM),
      propertyTypes: currentFilters.propertyTypes || null,
      businessPlans: currentFilters.businessPlans || null,
      investorLevelIrr: parseFloat(currentFilters.investorLevelIrr),
      yoc: parseFloat(currentFilters.yoc),
      missionDrivenStrategies: currentFilters.missionDrivenStrategies || null,
      minHoldPeriod: parseFloat(currentFilters.minHoldPeriod),
      maxHoldPeriod: parseFloat(currentFilters.maxHoldPeriod),
      broker: currentFilters.broker,
      sponsorExclusivity: currentFilters.sponsorExclusivity,
    }).unwrap();

    refetchMarketplaceDeals();
  }, [currentFilters.broker, currentFilters.businessPlans, currentFilters.cities, currentFilters.equityTypes, currentFilters.investorLevelIrr, currentFilters.maxCheckSizeMM, currentFilters.maxHoldPeriod, currentFilters.minCheckSizeMM, currentFilters.minHoldPeriod, currentFilters.missionDrivenStrategies, currentFilters.propertyTypes, currentFilters.sponsorExclusivity, currentFilters.states, currentFilters.yoc, refetchMarketplaceDeals, updateFilters]);

  useEffect(() => {
    if (account?.equityMarketplaceFilter != null) {
      setAccountFilter(account?.equityMarketplaceFilter);
    }
  }, [account?.equityMarketplaceFilter]);

  useEffect(() => {
    if (filters != null && isGetFiltersSuccess && previousFilters == null) {
      setCurrentFilters({
        equityTypes: filters.equityTypes ?? [],
        states: filters.states ?? [],
        cities: filters.cities ?? [],
        minCheckSizeMM: filters.minCheckSizeMM ?? '',
        maxCheckSizeMM: filters.maxCheckSizeMM ?? '',
        propertyTypes: filters.propertyTypes ?? [],
        businessPlans: filters.businessPlans ?? [],
        investorLevelIrr: filters.investorLevelIrr ?? '',
        yoc: filters.yoc ?? '',
        missionDrivenStrategies: filters.missionDrivenStrategies ?? [],
        minHoldPeriod: filters.minHoldPeriod ?? '',
        maxHoldPeriod: filters.maxHoldPeriod ?? '',
        broker: filters.broker ?? '',
        sponsorExclusivity: filters.sponsorExclusivity ?? '',
      });
    }
  }, [filters, isGetFiltersSuccess, previousFilters]);

  useEffectDebounced(() => {
    if (isGetFiltersSuccess) {
      updateFiltersAndRefetchDeals();
    }
  }, 500, [currentFilters]);

  return (
    <>
      <Card sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        padding: 2,
        background: 'linear-gradient(249.96deg, rgba(255, 255, 255, 0.8) 18.86%, rgba(255, 255, 255, 0) 62.55%), rgba(255, 255, 255, 0.5)',
      }}
      variant='outlined'>
        <FormControl
          sx={{
            width: '400px',
          }}
          size='small'>
          <InputLabel>{'Filter'}</InputLabel>
          <Select
            label='Filter'
            value={accountFilter}
            onChange={e => onAccountFilterChanged(e.target.value as typeof accountFilter)}>
            <MenuItem value='all'>{'Show All Projects'}</MenuItem>
            <MenuItem value='filtered'>{'Show Filtered Projects'}</MenuItem>
          </Select>
        </FormControl>

        <Divider/>
   
        <ToggleButtonGroup
          size='small'
          disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}
          value={currentFilters.equityTypes ?? []}
          onChange={(_e, value) => setCurrentFilters(p => ({...p, equityTypes: value}))}>
          {equityTypes.map(type => (
            <ToggleButton
              key={type.value}
              value={type.value}>
              {type.title}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
        }}>
          <Button
            sx={{
              alignSelf: 'flex-start'
            }}
            variant='outlined'
            color='secondary'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}
            onClick={() => showGeographicFocusDialog()}>
            {'Geographic Focus'}
          </Button>

          {currentFilters?.states &&
            <Typography sx={{
              opacity: account?.equityMarketplaceFilter !== 'filtered' ? 0.5 : 1.0,
            }}>
              {`${currentFilters.states.length} ${currentFilters.states.length === 1 ? 'state' : 'states'}, ${currentFilters.cities.length} ${currentFilters.cities.length === 1 ? 'city' : 'cities'}`}
            </Typography>
          }
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
        }}>
          <TextField
            label='Min Check Size ($MM)'
            size='small'
            type='number'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}
            value={currentFilters.minCheckSizeMM}
            onChange={e => setCurrentFilters(p => ({...p, minCheckSizeMM: e.target.value.length > 0 ? parseFloat(e.target.value) : '' as any}))} />

          <TextField
            label='Max Check Size ($MM)'
            size='small'
            type='number'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}
            value={currentFilters.maxCheckSizeMM}
            onChange={e => setCurrentFilters(p => ({...p, maxCheckSizeMM: e.target.value.length > 0 ? parseFloat(e.target.value) : '' as any}))} />
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
        }}>
          <FormControl
            sx={{width: '500px'}}
            size='small'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading || isPropertyTypeChoicesFetching}>
            <InputLabel id='propertyType'>{'Property Type'}</InputLabel>
            <Select
              labelId='propertyType'
              label='Property Type'
              multiple
              renderValue={choices => choices.join(', ')}
              value={currentFilters.propertyTypes}
              onChange={e => setCurrentFilters(p => ({...p, propertyTypes: e.target.value, propertySubtype: ''}))}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
              }}>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  fontStyle: 'italic',
                  color: '#333333AA',
                }}>
                  {'Select all that apply'}
                </Typography>
              </Box>
              {propertyTypeChoices?.select?.options.map(option => (
                <MenuItem
                  key={option.optionName}
                  value={option.optionName}>
                  <CheckRoundedIcon sx={{
                    fontSize: '18px',
                    color: '#333333AA',
                    opacity: currentFilters.propertyTypes?.includes(option.optionName) ? 1.0 : 0.0,
                  }} />
                  {option.optionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            sx={{width: '500px'}}
            size='small'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading || isBusinessPlanChoicesFetching || businessPlanChoices == null}>
            <InputLabel id='businessPlan'>{'Business Plan'}</InputLabel>
            <Select
              labelId='businessPlan'
              label='Business Plan'
              multiple
              renderValue={choices => choices.join(', ')}
              value={currentFilters.businessPlans}
              onChange={e => setCurrentFilters(p => ({...p, businessPlans: e.target.value}))}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
              }}>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  fontStyle: 'italic',
                  color: '#333333AA',
                }}>
                  {'Select all that apply'}
                </Typography>
              </Box>
              {businessPlanChoices?.select?.options.map(option => (
                <MenuItem
                  key={option.optionName}
                  value={option.optionName}>
                  <CheckRoundedIcon sx={{
                    fontSize: '18px',
                    color: '#333333AA',
                    opacity: currentFilters.businessPlans?.includes(option.optionName) ? 1.0 : 0.0,
                  }} />
                  {option.optionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
        }}>
          <TextField
            sx={{
              width: '230px',
            }}
            label='Min Investor IRR (%)'
            size='small'
            type='number'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}
            value={currentFilters.investorLevelIrr ?? ''}
            onChange={e => setCurrentFilters(p => ({...p, investorLevelIrr: e.target.value.length > 0 ? parseFloat(e.target.value) : null}))} />

          <TextField
            sx={{
              width: '230px',
            }}
            label='Min YOC (%)'
            size='small'
            type='number'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}
            value={currentFilters.yoc ?? ''}
            onChange={e => setCurrentFilters(p => ({...p, yoc: e.target.value.length > 0 ? parseFloat(e.target.value) : null}))} />

          <FormControl
            sx={{width: '500px'}}
            size='small'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading || isMissionDrivenStrategyChoicesFetching}>
            <InputLabel id='missionDrivenStrategy'>{'Mission-Driven Strategy'}</InputLabel>
            <Select
              labelId='missionDrivenStrategy'
              label='Mission-Driven Strategy'
              multiple
              renderValue={choices => choices.join(', ')}
              value={currentFilters.missionDrivenStrategies ?? []}
              onChange={e => setCurrentFilters(p => ({...p, missionDrivenStrategies: e.target.value}))}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
              }}>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  fontStyle: 'italic',
                  color: '#333333AA',
                }}>
                  {'Select all that apply'}
                </Typography>
              </Box>
              {missionDrivenStrategyChoices?.select?.options.map(option => (
                <MenuItem
                  key={option.optionName}
                  value={option.optionName}>
                  <CheckRoundedIcon sx={{
                    fontSize: '18px',
                    color: '#333333AA',
                    opacity: currentFilters.missionDrivenStrategies?.includes(option.optionName) ? 1.0 : 0.0,
                  }} />
                  {option.optionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            sx={{
              width: '230px',
            }}
            label='Min Hold Period (Years)'
            size='small'
            type='number'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}
            value={currentFilters.minHoldPeriod ?? ''}
            onChange={e => setCurrentFilters(p => ({...p, minHoldPeriod: e.target.value.length > 0 ? parseFloat(e.target.value) : null}))} />

          <TextField
            sx={{
              width: '230px',
            }}
            label='Max Hold Period (Years)'
            size='small'
            type='number'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}
            value={currentFilters.maxHoldPeriod ?? ''}
            onChange={e => setCurrentFilters(p => ({...p, maxHoldPeriod: e.target.value.length > 0 ? parseFloat(e.target.value) : null}))} />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
        }}>
          <FormControl
            sx={{width: '150px'}}
            size='small'
            disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}>
            <InputLabel id='broker'>{'Broker'}</InputLabel>
            <Select
              labelId='broker'
              label='Broker'
              value={currentFilters.broker}
              onChange={e => setCurrentFilters(p => ({...p, broker: e.target.value}))}>
              <MenuItem value={'Yes'}>
                {'Yes'}
              </MenuItem>
              <MenuItem value={'No'}>
                {'No'}
              </MenuItem>
            </Select>
          </FormControl>
          {currentFilters.broker === 'Yes' &&
            <FormControl
              sx={{width: '500px'}}
              size='small'
              disabled={account?.equityMarketplaceFilter !== 'filtered' || isFiltersLoading}>
              <InputLabel id='sponsorExclusivity'>{'Exclusivity'}</InputLabel>
              <Select
                labelId='sponsorExclusivity'
                label='Exclusivity'
                value={currentFilters.sponsorExclusivity}
                onChange={e => setCurrentFilters(p => ({...p, sponsorExclusivity: e.target.value}))}>
                <MenuItem value={'Show only projects with sponsor exclusivity'}>
                  {'Show only projects with sponsor exclusivity'}
                </MenuItem>
                <MenuItem value={'Show all projects'}>
                  {'Show all projects'}
                </MenuItem>
              </Select>
            </FormControl>
          }
        </Box>
      </Card>

      <EquityMarketplaceFilterGeographicFocusDialog
        {...geographicFocusDialogProps}
        filters={filters}
        saveFilters={(states, cities) => setCurrentFilters(p => ({...p, states, cities}))} />
    </>
  );
}
