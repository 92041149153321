import { Avatar, Box, Button, Dialog, DialogContent, DialogProps, Typography } from "@mui/material";
import { ILender } from "../../../schemas";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useMemo } from "react";


interface Props extends DialogProps {
  lender?: ILender;
}

export default function LenderDetailDialog({lender, ...dialogProps}: Props): JSX.Element {

  const contacts = useMemo(() => {
    if (lender == null) {
      return undefined;
    }
    
    const contacts: {name: string, email?: string, phone?: string}[] = [];
    
    if (lender['Name'] != null && (lender['Email'] != null || lender['Phone'] != null)) {
      contacts.push({
        name: lender['Name'] as string,
        email: lender['Email'] as string,
        phone: lender['Phone'] as string,
      });
    }

    if (lender['Name 2'] != null && (lender['Email 2'] != null || lender['Phone 2'] != null)) {
      contacts.push({
        name: lender['Name 2'] as string,
        email: lender['Email 2'] as string,
        phone: lender['Phone 2'] as string,
      });
    }

    return contacts;
  }, [lender]);

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title={
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 4,
          }}>
            <Avatar
              sx={{
                width: '96px',
                height: '96px',
              }}
              variant='rounded'
              src={lender?.LogoUrl as string}
              alt='logo' />
            <Box>
              <Typography sx={{
                fontWeight: '500',
                fontSize: '26px',
              }}>
                {lender?.Lender}
              </Typography>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '16px',
                color: '#33333380',
              }}>
                {lender != null ? lender['Lending Institution'] : undefined}
              </Typography>
            </Box>
          </Box>
        }
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />

      <DialogContent>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 2,
            borderRadius: '8px',
            background: '#DDCFB21A'
          }}>
            {lender?.Website != null &&
              <Box sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  alignItems: 'center',
                }}>
                  <LanguageRoundedIcon sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    color: '#33333380',
                  }} />
                  <Typography sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    color: '#33333380',
                  }}>
                    {'Website'}
                  </Typography>
                </Box>
                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'
                  href={(lender?.Website as string) ?? '#'}
                  target='_blank'
                  rel='noopener noreferrer'>
                  <OpenInNewIcon fontSize='small' />
                  {'Open Website'}
                </Button>
              </Box>
            }
            {lender != null && lender['Term Sheet'] != null &&
              <Box sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  alignItems: 'center',
                }}>
                  <DescriptionOutlinedIcon sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    color: '#33333380',
                  }} />
                  <Typography sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    color: '#33333380',
                  }}>
                    {'Term Sheet'}
                  </Typography>
                </Box>

                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'>
                  <FileDownloadOutlinedIcon fontSize='small' />
                  {'Download Term Sheet'}
                </Button>
              </Box>
            }
          </Box>
 
          {contacts != null && contacts.length > 0 &&
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              justifyContent: 'space-around',

              borderRadius: '8px',
              borderWidth: '1px',
              borderColor: '#33333314',
              borderStyle: 'solid',
            }}>
              {contacts.map(contact => (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 2,
                  maxWidth: '45%',
                  overflow: 'hidden',
                }}>
                  <Typography sx={{
                    fontWeight: '500',
                    fontSize: '16px',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                  }}>
                    {contact.name}
                  </Typography>
                  {contact.email &&
                    <Typography sx={{
                      overflowWrap: 'break-word',
                      wordWrap: 'break-word',
                    }}>
                      {contact.email}
                    </Typography>
                  }
                  {contact.phone != null &&
                    <Typography sx={{
                      overflowWrap: 'break-word',
                      wordWrap: 'break-word',
                    }}>
                      {contact.phone}
                    </Typography>
                  }
                </Box>
              ))}
            </Box>
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
}
