import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { PropsWithChildren, ReactNode, useCallback, useEffect, useState } from "react";
import { Params, useLoaderData } from "react-router-dom";
import { useDeleteDiligenceContactMutation, useGetDealQuery, useUpdateDiligenceMutation } from "../../features/deals-api";
import dayjs, { Dayjs } from "dayjs";
import DiligenceProgressMeter from "../Deal/DiligenceProgressMeter";
import { IDealDiligence, IDealDiligenceContact } from "../../schemas";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useGetCurrentAccountQuery } from "../../features/accounts-api";
import * as Sentry from '@sentry/react';
import { useAppDispatch } from "../../app/hooks";
import { snacked } from "../../features/snackMessage-slice";
import { usePopoverContext } from "../../utils/hooks";
import DiligenceContactDialog from "../Deal/Dialogs/DiligenceContactDialog";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditIcon from '@mui/icons-material/Edit';
import ContactInfoCard from "../Deal/ContactInfoCard";
import LoadingButton from "../LoadingButton";


type SectionProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
  notes?: string;
  onNotesChange?: (value: string) => void;
  actions?: ReactNode;
  alwaysShowNotes?: boolean;
  editing?: boolean;
}>;

function Section({title, subtitle, notes, onNotesChange, actions, alwaysShowNotes, editing, children}: SectionProps): JSX.Element {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 4,
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <Typography sx={{
              fontWeight: '500',
              fontSize: '20px',
              color: '#333333',
            }}>
              {title}
            </Typography>

            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              color: '#33333380',
            }}>
              {subtitle}
            </Typography>
          </Box>
          
          {/* {actions} */}
        </Box>
        
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 3,
          paddingBottom: 2,
        }}>
          {children}
        </Box>
      </Box>

      <Divider
        sx={{
          bgcolor: '#333333',
          borderWidth: '0.5px',
          opacity: 0.08,
        }}
        orientation='vertical'
        flexItem />

      <Box sx={{
        display: 'flex',
        width: '25%',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          flex: 1,
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 1,
          }}>
            <Typography sx={{
              fontWeight: '500',
              fontSize: '16px',
            }}>
              {'Notes'}
            </Typography>

            {actions}
          </Box>

          {editing ? (
            <TextField
              InputLabelProps={{
                style: {
                  fontSize: '14px',
                }
              }}
              placeholder='Write a note...'
              fullWidth
              multiline
              minRows={4}
              maxRows={10}
              value={notes}
              onChange={e => onNotesChange ? onNotesChange(e.target.value) : null} />
          ) : (
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              whiteSpace: 'pre-wrap',
              color: '#33333380',
            }}>
              {notes}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}


interface AddContactButtonProps {
  onClick?: () => void;
}

function AddContactButton({onClick}: AddContactButtonProps): JSX.Element {
  return (
    <Button
      sx={{
        width: '100%',
        padding: 2,
        gap: 1,
        fontSize: '16px',
        color: '#33333380',
        borderRadius: '12px',
        borderStyle: 'dashed',
      }}
      variant='outlined'
      color='secondary'
      onClick={onClick}>
      <AddRoundedIcon />
      {'Add contact'}
    </Button>
  );
}


export default function DealProgressTable(): JSX.Element {

  const dispatch = useAppDispatch();

  const { dealId } = useLoaderData() as Params;

  const {
    props: contactDialogProps,
    showDialog: showContact,
    currentData: contactDialogData,
  } = usePopoverContext<{type: 'lender' | 'borrower' | 'insurance' | 'legal' | 'title', contact?: IDealDiligenceContact}>();

  const {data: account} = useGetCurrentAccountQuery();
  const {data: deal} = useGetDealQuery({dealId: dealId!}, { skip: dealId == null });

  const [editingInsurance, setEditingInsurance] = useState(false);
  const [editing3rdPartyReports, setEditing3rdPartyReports] = useState(false);
  const [editingSiteVisit, setEditingSiteVisit] = useState(false);
  const [editingLegal, setEditingLegal] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [diligence, setDiligence] = useState<Partial<IDealDiligence>>({});

  const [updateInsurance, { isLoading: isUpdateInsuranceLoading }] = useUpdateDiligenceMutation();
  const [update3rdPartyReports, { isLoading: isUpdate3rdPartyReportsLoading }] = useUpdateDiligenceMutation();
  const [updateSiteVisit, { isLoading: isUpdateSiteVisitLoading }] = useUpdateDiligenceMutation();
  const [updateLegal, { isLoading: isUpdateLegalLoading }] = useUpdateDiligenceMutation();
  const [updateTitle, { isLoading: isUpdateTitleLoading }] = useUpdateDiligenceMutation();
  const [deleteContact] = useDeleteDiligenceContactMutation();


  const canEditDiligence = account?.isAdmin || (deal?.whiteGlove?.status !== 'enabled' && (account?.type === 'sponsor' || account?.type === 'broker'));

  const diligenceValueChanged = useCallback((sectionKey: keyof IDealDiligence, key: string, value: any) => {
    const section: any = diligence[sectionKey as keyof IDealDiligence] ?? {}
    
    setDiligence({
      ...diligence,
      [sectionKey]: {
        ...section,
        [key]: value ?? null,
      }
    });
  }, [diligence]);

  const saveChangesClicked = useCallback(async (key: keyof IDealDiligence) => {
    if (deal?._id == null) {
      return;
    }
    
    try {
      switch (key) {
        case 'insurance':
          await updateInsurance({
            dealId: deal._id,
            diligence: {
              insurance: {
                notes: diligence.insurance?.notes
              }
            }
          }).unwrap();

          setEditingInsurance(false);
          break;
        case 'thirdPartyReports':
          await update3rdPartyReports({
            dealId: deal._id,
            diligence: {
              thirdPartyReports: {
                appraisalOrderDt: diligence.thirdPartyReports?.appraisalOrderDt,
                appraisalDueDt: diligence.thirdPartyReports?.appraisalDueDt,
                notes: diligence.thirdPartyReports?.notes,  
              }
            }
          }).unwrap();

          setEditing3rdPartyReports(false);
          break;
        case 'siteVisit':
          await updateSiteVisit({
            dealId: deal._id,
            diligence: {
              siteVisit: {
                siteVisitDt: diligence.siteVisit?.siteVisitDt,
                notes: diligence.siteVisit?.notes,
              }  
            }
          }).unwrap();

          setEditingSiteVisit(false);
          break;
        case 'legal':
          await updateLegal({
            dealId: deal._id,
            diligence: {
              legal: {
                notes: diligence.legal?.notes,
              }
            }
          }).unwrap();

          setEditingLegal(false);
          break;
        case 'title':
          await updateTitle({
            dealId: deal._id,
            diligence: {
              title: {
                notes: diligence.title?.notes,
              }
            }
          }).unwrap();

          setEditingTitle(false);
          break;
      }

      dispatch(snacked({
        message: 'Saved progress',
        severity: 'success',
      }));
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed saving changes',
        severity: 'error',
      }));
    }
  }, [deal?._id, diligence.insurance?.notes, diligence.legal?.notes, diligence.siteVisit?.notes, diligence.siteVisit?.siteVisitDt, diligence.thirdPartyReports?.appraisalDueDt, diligence.thirdPartyReports?.appraisalOrderDt, diligence.thirdPartyReports?.notes, diligence.title?.notes, dispatch, update3rdPartyReports, updateInsurance, updateLegal, updateSiteVisit, updateTitle]);

  const deleteContactClicked = useCallback(async (type: 'insurance' | 'legal' | 'title', contactId: string) => {
    if (deal?._id == null) {
      return;
    }

    try {
      await deleteContact({
        dealId: deal._id,
        type: type,
        contactId: contactId,
      }).unwrap();

      dispatch(snacked({
        message: 'Contact deleted',
        severity: 'success',
      }));

      // if (dialogProps.onClose != null) {
      //   dialogProps.onClose({}, 'escapeKeyDown');
      // }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed deleting contact',
        severity: 'error',
      }));
    }
  }, [deal?._id, deleteContact, dispatch]);

  useEffect(() => {
    if (deal != null) {
      const newDiligence: Partial<IDealDiligence> = {};

      if (!editingInsurance) {
        newDiligence.insurance = deal.diligence?.insurance;
      }

      if (!editing3rdPartyReports) {
        newDiligence.thirdPartyReports = deal.diligence?.thirdPartyReports;
      }

      if (!editingSiteVisit) {
        newDiligence.siteVisit = deal.diligence?.siteVisit;
      }

      if (!editingLegal) {
        newDiligence.legal = deal.diligence?.legal;
      }

      if (!editingTitle) {
        newDiligence.title = deal.diligence?.title;
      }

      setDiligence(previous => ({...previous, ...newDiligence}));
    }
  }, [deal, editing3rdPartyReports, editingInsurance, editingLegal, editingSiteVisit, editingTitle]);
  
  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}>
        <Divider />

        <DiligenceProgressMeter dealId={dealId} />

        <Divider />

        <Section
          title='Insurance contact(s)'
          subtitle='Please add your insurance contact(s) information below.'
          notes={diligence?.insurance?.notes}
          onNotesChange={value => diligenceValueChanged('insurance', 'notes', value)}
          alwaysShowNotes={canEditDiligence}
          editing={editingInsurance}
          actions={
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }}>
              {canEditDiligence && !editingInsurance && diligence.insurance?.contacts != null && diligence.insurance?.contacts.length !== 0 &&
                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'
                  size='small'
                  disabled={editingInsurance}
                  onClick={() => showContact({type: 'insurance'})}>
                  <AddRoundedIcon sx={{
                    fontSize: '18px',
                  }} />
                  {'Add'}
                </Button>
              }

              {editingInsurance ? (
                <>
                  <Button
                    sx={{
                      gap: 1,
                    }}
                    variant='outlined'
                    size='small'
                    color='secondary'
                    onClick={() => setEditingInsurance(false)}>
                    {'Cancel'}
                  </Button>

                  <LoadingButton
                    sx={{
                      gap: 1,
                    }}
                    variant='contained'
                    size='small'
                    loading={isUpdateInsuranceLoading}
                    onClick={() => saveChangesClicked('insurance')}>
                    {'Save'}
                  </LoadingButton>
                </>
              ) : (canEditDiligence &&
                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'
                  size='small'
                  onClick={() => setEditingInsurance(true)}>
                  <EditIcon sx={{
                    fontSize: '16px',
                  }} />
                  {'Edit'}
                </Button>
              )}
            </Box>
          }>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
            flexWrap: 'wrap',
            width: '100%',
          }}>
            {deal?.diligence?.insurance?.contacts == null || deal.diligence.insurance.contacts.length === 0 ? (
              canEditDiligence && <AddContactButton onClick={() => showContact({type: 'insurance'})} />
            ) : (
              deal?.diligence?.insurance?.contacts?.map(contact => (
                <ContactInfoCard
                  sx={{
                    maxWidth: '150px',
                  }}
                  key={contact._id}
                  role={contact.role}
                  name={contact.name}
                  email={contact.email}
                  phone={contact.phone}
                  address={contact.address}
                  onEditClicked={canEditDiligence ? () => showContact({type: 'insurance', contact: contact}) : undefined}
                  onDeleteClicked={canEditDiligence ? () => deleteContactClicked('insurance', contact._id) : undefined} />
              ))
            )}
          </Box>
        </Section>

        <Divider />

        <Section
          title='Legal contact(s)'
          subtitle='Please add your legal contact(s) information below.'
          notes={diligence?.legal?.notes}
          onNotesChange={value => diligenceValueChanged('legal', 'notes', value)}
          alwaysShowNotes={canEditDiligence}
          editing={editingLegal}
          actions={(
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }}>
              {canEditDiligence && !editingLegal && diligence.legal?.contacts != null && diligence.legal.contacts.length !== 0 &&
                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'
                  size='small'
                  onClick={() => showContact({type: 'legal'})}>
                  <AddRoundedIcon sx={{
                    fontSize: '18px',
                  }} />
                  {'Add'}
                </Button>
              }
  
              {editingLegal ? (
                <>
                  <Button
                    sx={{
                      gap: 1,
                    }}
                    variant='outlined'
                    size='small'
                    color='secondary'
                    onClick={() => setEditingLegal(false)}>
                    {'Cancel'}
                  </Button>
  
                  <LoadingButton
                    sx={{
                      gap: 1,
                    }}
                    variant='contained'
                    size='small'
                    loading={isUpdateLegalLoading}
                    onClick={() => saveChangesClicked('legal')}>
                    {'Save'}
                  </LoadingButton>
                </>
              ) : (canEditDiligence &&
                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'
                  size='small'
                  onClick={() => setEditingLegal(true)}>
                  <EditIcon sx={{
                    fontSize: '16px',
                  }} />
                  {'Edit'}
                </Button>
              )}
            </Box>
          )}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 3,
            flexWrap: 'wrap',
          }}>
            {deal?.diligence?.legal?.contacts == null || deal.diligence.legal.contacts.length === 0 ? (
              canEditDiligence && <AddContactButton onClick={() => showContact({type: 'legal'})} />
            ) : (
              deal?.diligence?.legal?.contacts?.map(contact => (
                <ContactInfoCard
                  sx={{
                    maxWidth: '150px',
                  }}
                  key={contact._id}
                  role={contact.role}
                  name={contact.name}
                  email={contact.email}
                  phone={contact.phone}
                  address={contact.address}
                  onEditClicked={canEditDiligence ? () => showContact({type: 'legal', contact: contact}) : undefined}
                  onDeleteClicked={canEditDiligence ? () => deleteContactClicked('legal', contact._id) : undefined} />
              ))
            )}
          </Box>
        </Section>
        <Divider />
        <Section
          title='Title contact(s)'
          subtitle='Please add your title owner information below.'
          notes={diligence?.title?.notes}
          onNotesChange={value => diligenceValueChanged('title', 'notes', value)}
          alwaysShowNotes={canEditDiligence}
          editing={editingTitle}
          actions={
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }}>
              {canEditDiligence && !editingTitle && diligence.title?.contacts != null && diligence.title.contacts.length !== 0 &&
                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'
                  size='small'
                  onClick={() => showContact({type: 'title'})}>
                  <AddRoundedIcon sx={{
                    fontSize: '18px',
                  }} />
                  {'Add'}
                </Button>  
              }

              {editingTitle ? (
                <>
                  <Button
                    sx={{
                      gap: 1,
                    }}
                    variant='outlined'
                    size='small'
                    color='secondary'
                    onClick={() => setEditingTitle(false)}>
                    {'Cancel'}
                  </Button>
  
                  <LoadingButton
                    sx={{
                      gap: 1,
                    }}
                    variant='contained'
                    size='small'
                    loading={isUpdateTitleLoading}
                    onClick={() => saveChangesClicked('title')}>
                    {'Save'}
                  </LoadingButton>
                </>
              ) : (canEditDiligence &&
                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'
                  size='small'
                  onClick={() => setEditingTitle(true)}>
                  <EditIcon sx={{
                    fontSize: '16px',
                  }} />
                  {'Edit'}
                </Button>
              )}
            </Box>
          }>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 3,
            flexWrap: 'wrap',
          }}>
            {deal?.diligence?.title?.contacts == null || deal.diligence.title.contacts.length === 0 ? (
              canEditDiligence && <AddContactButton onClick={() => showContact({type: 'title'})} />
            ) : (
              deal?.diligence?.title?.contacts?.map(contact => (
                <ContactInfoCard
                  sx={{
                    maxWidth: '150px',
                  }}
                  key={contact._id}
                  role={contact.role}
                  name={contact.name}
                  email={contact.email}
                  phone={contact.phone}
                  address={contact.address}
                  onEditClicked={canEditDiligence ? () => showContact({type: 'title', contact: contact}) : undefined}
                  onDeleteClicked={canEditDiligence ? () => deleteContactClicked('title', contact._id) : undefined} />
              ))
            )}
          </Box>
        </Section>

        <Divider />

        <Section
          title='Third party reports'
          subtitle='Please fill out the following information.'
          notes={diligence?.thirdPartyReports?.notes}
          onNotesChange={value => diligenceValueChanged('thirdPartyReports', 'notes', value)}
          alwaysShowNotes={canEditDiligence}
          editing={editing3rdPartyReports}
          actions={(
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }}>
              {canEditDiligence && !editing3rdPartyReports &&
                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'
                  size='small'
                  onClick={() => setEditing3rdPartyReports(true)}>
                  <EditIcon sx={{
                    fontSize: '16px',
                  }} />
                  {'Edit'}
                </Button>
              }

              {canEditDiligence && editing3rdPartyReports &&
                <>
                  <Button
                    sx={{
                      gap: 1,
                    }}
                    variant='outlined'
                    size='small'
                    color='secondary'
                    onClick={() => setEditing3rdPartyReports(false)}>
                    {'Cancel'}
                  </Button>

                  <LoadingButton
                    sx={{
                      gap: 1,
                    }}
                    variant='contained'
                    size='small'
                    loading={isUpdate3rdPartyReportsLoading}
                    onClick={() => saveChangesClicked('thirdPartyReports')}>
                    {'Save'}
                  </LoadingButton>
                </>
              }
            </Box>
          )}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
            flexWrap: 'wrap',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '264px',
              padding: '24px',
              borderRadius: '12px',
              background: 'white',
              boxShadow: '0px 0px 16px 0px #3333331F',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
                color: '#33333380',
              }}>
                {'Appraisal order date'}
              </Typography>
              
              {canEditDiligence && editing3rdPartyReports ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: false,
                        InputProps: {
                          sx: {
                            borderRadius: '8px',
                          }
                        }
                      }
                    }}
                    value={diligence.thirdPartyReports?.appraisalOrderDt != null ? dayjs(diligence.thirdPartyReports?.appraisalOrderDt) : null}
                    onChange={value => diligenceValueChanged('thirdPartyReports', 'appraisalOrderDt', value?.toISOString())} />
                </LocalizationProvider>
              ) : (
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  color: deal?.diligence?.thirdPartyReports?.appraisalOrderDt == null ? '#333333AA' : undefined,
                }}>
                  {deal?.diligence?.thirdPartyReports?.appraisalOrderDt != null ? dayjs(deal?.diligence?.thirdPartyReports?.appraisalOrderDt).format('MM/DD/YYYY') : 'TBD'}
                </Typography>
              )}
            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '264px',
              padding: '24px',
              borderRadius: '12px',
              background: 'white',
              boxShadow: '0px 0px 16px 0px #3333331F',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
                color: '#33333380',
              }}>
                {'Appraisal due date'}
              </Typography>

              {canEditDiligence && editing3rdPartyReports ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: false,
                        InputProps: {
                          sx: {
                            borderRadius: '8px',
                          }
                        }
                      }
                    }}
                    value={diligence.thirdPartyReports?.appraisalDueDt != null ? dayjs(diligence.thirdPartyReports?.appraisalDueDt) : null}
                    onChange={(value: Dayjs | null) => diligenceValueChanged('thirdPartyReports', 'appraisalDueDt', value?.toISOString())} />
                </LocalizationProvider>
              ) : (
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  color: deal?.diligence?.thirdPartyReports?.appraisalDueDt == null ? '#333333AA' : undefined,
                }}>
                  {deal?.diligence?.thirdPartyReports?.appraisalDueDt != null ? dayjs(deal?.diligence?.thirdPartyReports?.appraisalDueDt).format('MM/DD/YYYY') : 'TBD'}
                </Typography>
              )}
            </Box>
          </Box>
        </Section>

        <Divider />

        <Section
          title='Site visit'
          subtitle='Please fill out the following information.'
          notes={diligence?.siteVisit?.notes}
          onNotesChange={value => diligenceValueChanged('siteVisit', 'notes', value)}
          alwaysShowNotes={canEditDiligence}
          editing={editingSiteVisit}
          actions={
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }}>
              {canEditDiligence && !editingSiteVisit &&
                <Button
                  sx={{
                    gap: 1,
                  }}
                  variant='contained'
                  size='small'
                  onClick={() => setEditingSiteVisit(true)}>
                  <EditIcon sx={{
                    fontSize: '16px',
                  }} />
                  {'Edit'}
                </Button>
              }

              {canEditDiligence && editingSiteVisit &&
                <>
                  <Button
                    sx={{
                      gap: 1,
                    }}
                    variant='outlined'
                    size='small'
                    color='secondary'
                    onClick={() => setEditingSiteVisit(false)}>
                    {'Cancel'}
                  </Button>

                  <LoadingButton
                    sx={{
                      gap: 1,
                    }}
                    variant='contained'
                    size='small'
                    loading={isUpdateSiteVisitLoading}
                    onClick={() => saveChangesClicked('siteVisit')}>
                    {'Save'}
                  </LoadingButton>
                </>
              }
            </Box>
          }>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '264px',
            padding: '24px',
            borderRadius: '12px',
            background: 'white',
            boxShadow: '0px 0px 16px 0px #3333331F',
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '17.12px',
              color: '#33333380',
            }}>
              {'Site visit date'}
            </Typography>

            {canEditDiligence && editingSiteVisit ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: false,
                      InputProps: {
                        sx: {
                          borderRadius: '8px',
                        }
                      }
                    }
                  }}
                  value={diligence.siteVisit?.siteVisitDt != null ? dayjs(diligence.siteVisit?.siteVisitDt) : null}
                  onChange={value => diligenceValueChanged('siteVisit', 'siteVisitDt', value?.toISOString())} />
                </LocalizationProvider>
            ) : (
              <Typography sx={{
                fontWeight: '400',
                fontSize: '16px',
                color: deal?.diligence?.siteVisit?.siteVisitDt == null ? '#333333AA' : undefined,
              }}>
                {deal?.diligence?.siteVisit?.siteVisitDt != null ? dayjs(deal?.diligence?.siteVisit?.siteVisitDt).format('MM/DD/YYYY') : 'TBD'}
              </Typography>
            )}
          </Box>
        </Section>
      </Box>

      <DiligenceContactDialog
        {...contactDialogProps}
        deal={deal}
        type={contactDialogData?.type ?? 'insurance'}
        contact={contactDialogData?.contact}
        hasRole={contactDialogData?.type === 'legal'} />
    </>
  );
}
