import { Button, DialogActions, DialogContent, DialogContentText, DialogProps } from "@mui/material";
import { IDeal } from "../../../schemas";


interface Props {
  deal?: IDeal;
  dialogProps: DialogProps;
  setState: (state: 'info' | 'complete') => void;
}

export default function WhiteGloveCompleteContent({deal, dialogProps, setState}: Props): JSX.Element {
  return (
    <>
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}>
        <DialogContentText>
          {`Congratulations! CREED's White Glove Service has been ${deal?.whiteGlove?.status === 'enabled' ? 'activated' : 'requested'}.`}
        </DialogContentText>
        <DialogContentText>
          {'CREED\'s Capital Markets team will be in touch with you shortly to discuss next steps.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          variant='contained'
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Got it'}
        </Button>
      </DialogActions>
    </>
  );
}
