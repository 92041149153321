import { useEffect, useCallback, useMemo, useState } from 'react';
import { Box, Avatar, Tooltip, Button, styled, IconButton, Badge, Link } from '@mui/material';
import { IDeal, ILender } from '../../../schemas';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useFavoriteSearchResultMutation, useSearchLendersQuery } from '../../../features/deals-api';
import { usePopoverContext, usePrevious } from '../../../utils/hooks';
import { wrapColumnsInTooltips } from '../../../utils/componentUtils';
import { DataGridPro, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import LenderDetailDialog from '../Dialogs/LenderDetailDialog';
import UpgradeSubscriptionDialog from '../../Dialogs/UpgradeSubscriptionDialog';
import { useGetCurrentAccountQuery } from '../../../features/accounts-api';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import { useGetLenderMetadataForLendersQuery } from '../../../features/lenders-api';
import LenderReviewsDialog from '../Dialogs/LenderReviewsDialog';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';



const StyledDataGridPro = styled(DataGridPro)({
  '& .row-favorited': {
    backgroundColor: '#FA793810',
    '&:hover': {
      backgroundColor: '#FA793804',
    }
  },
});

interface Props {
  readonly deal?: IDeal;
}

export default function LenderSearchResultsDataGrid({deal}: Props): JSX.Element {

  const {
    props: purchaseSubscriptionDialogProps,
    showDialog: showPurchaseSubscription,
  } = usePopoverContext();

  const {
    props: lenderDetailDialogProps,
    showDialog: showLenderDetail,
    currentData: lenderDetailLender,
  } = usePopoverContext<ILender>();

  const {
    props: lenderReviewsDialogProps,
    showDialog: showLenderReviews,
    currentData: lenderReviewLender,
  } = usePopoverContext<string>();

  const [loading, setLoading] = useState(false);

  const previousDealCreatedDt = usePrevious(deal?.createdDt);

  const { data: account } = useGetCurrentAccountQuery();
  const { data: searchResults, isLoading: isLoadingSearchResults, refetch: refetchSearchResults } = useSearchLendersQuery({ dealId: deal?._id!, filter: 'search' }, { skip: deal?._id == null });
  const { data: favoritedLenders, isLoading: isLoadingFavoriteLenders } = useSearchLendersQuery({ dealId: deal?._id!, filter: 'favorites' }, { skip: deal?._id == null });
  const { data: lenderMetadata } = useGetLenderMetadataForLendersQuery({ lenderNames: searchResults?.map(l => l.Lender as string)! }, { skip: searchResults == null });

  const [favoriteSearchResult] = useFavoriteSearchResultMutation();

  const favoriteClicked = useCallback((_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, lender: ILender) => {
    if (deal?._id == null) {
      return;
    }

    const isFavorite = favoritedLenders?.find(l => l.Lender === lender.Lender) != null;

    favoriteSearchResult({
      dealId: deal._id,
      lenderId: lender._id,
      lenderName: lender.Lender as string,
      isFavorite: !isFavorite,
    });
  }, [deal?._id, favoritedLenders, favoriteSearchResult]);

  const columns: GridColDef<ILender>[] = useMemo(() => {
    return wrapColumnsInTooltips([
      {
        field: 'LogoUrl',
        headerName: '',
        width: 36 + 56,
        renderCell: (params: any) => (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          }}>
            {favoritedLenders?.find(l => l.Lender === params.row.Lender) ? (
              <StarRoundedIcon sx={{color: '#33333380'}} />
            ) : (
              <StarBorderRoundedIcon sx={{color: '#33333380'}} />
            )}

            <Avatar
              sx={{
                width: '36px',
                height: '36px',
              }}
              variant='rounded'
              src={params.value} />
          </Box>
        )
      },
      {
        field: 'Lender',
        headerName: 'Lender Name',
        flex: 1,
        renderCell: account?.type === 'broker' && account.currentSubscription?.isActive && account.currentSubscription.type === 'broker-pro' 
          ? (params) => (
            <Link
              href={(params.row.Website as string) ?? '#'}
              target='_blank'
              rel='noopener noreferrer'
              variant='inherit'>
              {params.value}
            </Link>
          )
          : undefined,
      },
      account?.type === 'broker' ? {
        field: 'contactInfo',
        headerName: 'Contact Info',
        width: 75,
        renderCell: (params) => (
          account.currentSubscription?.type === 'broker-basic' ? (
            <IconButton onClick={() => showPurchaseSubscription()}>
              <LocalPhoneIcon fontSize='small' />
            </IconButton>
          ) : account.currentSubscription?.type === 'broker-pro' ? (
            <IconButton onClick={() => showLenderDetail(params.row)}>
              <LocalPhoneIcon fontSize='small' />
            </IconButton>
          ) : (<></>)
        )
      } : undefined,
      {
        field: 'Loan Type',
        headerName: 'Loan Type',
        width: 160,
      },
      {
        field: 'Lending Institution',
        headerName: 'Lending Institution',
        width: 110,
      },
      {
        field: 'All in rate Min',
        headerName: 'Interest Rate',
        width: 120,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
          if (params.value == null) {
            return '';
          }          

          try {
            const min = params.value.toFixed(2);
            const row = params.api.getRow(params.id!);
            const max = row['All in rate Max'] as number;
            
            if (min != null && max != null) {
              return `${min}% - ${max.toFixed(2)}%`;
            } else if (min != null) {
              return `${min}%`;
            }
  
            return '';
          } catch {
            return '';
          }
        }
      },
      {
        field: 'Points',
        headerName: 'Fee',
        width: 60,
      },
      {
        field: 'DSCR Min',
        headerName: 'DSCR Min',
        width: 80
      },
      {
        field: 'Debt Yield Min',
        headerName: 'Debt Yield Min',
        width: 80,
      },
      {
        field: 'Prepayment Type',
        headerName: 'Prepayment Type',
        width: 170,
      },
      {
        field: 'LTV Max',
        headerName: 'LTV Max',
        width: 80,
        valueFormatter: (params: any) => `${params.value}%`,
      },
      account?.type === 'sponsor' || (account?.type === 'broker' && account.currentSubscription?.isActive && account?.currentSubscription?.type === 'broker-pro') ? {
        field: 'actions',
        headerName: '',
        width: 120,
        renderCell: (params: any) => (
          <Tooltip
            title={favoritedLenders?.find(l => l.Lender === params.row.Lender) ? null : 'Save this lender to your Favorites list'}
            placement='right'
            enterDelay={500}
            arrow>
            <span>
              <Button
                variant='text'
                disabled={deal?.noContactLenders?.some(l => l.name === params.row.Lender)}
                onClick={e => favoriteClicked(e, params.row)}>
                {favoritedLenders?.find(l => l.Lender === params.row.Lender) ? 'Un-favorite' : 'Favorite'}
              </Button>
            </span>
          </Tooltip>
        )
      } : undefined,
      {
        field: 'info',
        headerName: 'Ratings / Reviews',
        disableColumnMenu: true,
        sortable: false,
        width: 75,
        renderCell: (params) => {          
          return (
            <Badge
              invisible={!Boolean(lenderMetadata?.find(m => m.lenderName === params.row.Lender)?.reviews)}
              color='warning'
              overlap='circular'
              variant='dot'>
              <IconButton
                onClick={() => showLenderReviews(params.row.Lender as string)}>
                <NotesRoundedIcon
                  sx={{
                    color: 'inherit',
                  }}
                  fontSize='small' />
              </IconButton>
            </Badge>
          );
        }
      },
      
    ]);
  }, [account?.type, account?.currentSubscription?.isActive, account?.currentSubscription?.type, favoritedLenders, deal?.noContactLenders, favoriteClicked, lenderMetadata, showLenderReviews, showPurchaseSubscription, showLenderDetail]);

  useEffect(() => {
    async function refetch() {
      if (previousDealCreatedDt != null) {
        setLoading(true);
        await refetchSearchResults();
        setLoading(false);
      }
    }

    refetch();
    // eslint-disable-next-line
  }, [deal?.updatedDt, refetchSearchResults]);

  return (
    <>
      <StyledDataGridPro
        sx={{
          fontWeight: '400',
          fontSize: '12px',
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
          },
          '& .MuiDataGrid-columnHeader': {
            // Forced to use important since overriding inline styles
            // height: 'unset !important',
            outline: 'none !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            // Forced to use important since overriding inline styles
            maxHeight: '168px !important'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          }
        }}
        columns={columns as any}
        rows={searchResults ?? []}
        getRowId={r => r._id}
        columnHeaderHeight={50}
        autoPageSize
        disableColumnSelector
        disableColumnReorder
        loading={loading || isLoadingSearchResults || isLoadingFavoriteLenders}
        isRowSelectable={() => false}
        // onRowClick={params => showLenderDetail(params.row)}
        initialState={{
          pinnedColumns: {
            right: ['actions']
          }
        }}
        getRowClassName={params => {
          const favorited = deal?.favoriteLenders?.some(l => l.name === params.row.Lender);
          if (favorited) {
            return 'row-favorited';
          }

          return '';
        }} />

      <UpgradeSubscriptionDialog
        {...purchaseSubscriptionDialogProps} />

      <LenderDetailDialog
        {...lenderDetailDialogProps}
        lender={lenderDetailLender} />

      <LenderReviewsDialog
        {...lenderReviewsDialogProps}
        lenderName={lenderReviewLender} />
    </>
  );
}
