import { Box, Button, Collapse, SxProps, TextField, Tooltip, Typography } from "@mui/material";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import LoadingButton from "../LoadingButton";
import { useAppDispatch } from "../../app/hooks";
import { snacked } from "../../features/snackMessage-slice";
import { Theme } from "@mui/material/styles";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import * as Sentry from '@sentry/react';


interface Props {
  sx?: SxProps<Theme>;
  title: string;
  description?: string;
  editable?: boolean;
  defaultContent?: string;
  infoContent?: ReactNode;
  onSaveClicked?: (content: string) => Promise<void>;
}

export default function CollapsibleTextEntry({sx, title, description, editable, defaultContent, infoContent, onSaveClicked}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const defaultCollapsedHeight = 70; // 70px

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [collapsable, setCollapsable] = useState(false);
  const [collapsedHeight, setCollapsedHeight] = useState(defaultCollapsedHeight);
  const [content, setContent] = useState(defaultContent ?? '');
  const [editing, setEditing] = useState(false);

  const contentRef = useRef<HTMLParagraphElement>();

  const collapseClicked = useCallback(() => {
    if (collapsable && !expanded) {
      setExpanded(true);
    }
  }, [collapsable, expanded]);

  const editClicked = useCallback(() => {
    setExpanded(true);
    setEditing(true);
  }, []);

  const saveClicked = useCallback(async () => {
    try {
      setLoading(true);

      if (onSaveClicked != null) {
        await onSaveClicked(content);
      }

      setEditing(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Save failed',
        severity: 'error',
      }));
    } finally {
      setLoading(false);
    }
  }, [content, dispatch, onSaveClicked]);

  const cancelClicked = useCallback(() => {
    setEditing(false);
    setContent(defaultContent ?? '');
  }, [defaultContent]);

  useEffect(() => {
    const height = contentRef.current?.offsetHeight;
    if (height != null && height < defaultCollapsedHeight) {
      setExpanded(false);
      setCollapsedHeight(height + 20);
    } else if (height != null && height > defaultCollapsedHeight) {
      setCollapsedHeight(defaultCollapsedHeight);
      setCollapsable(true);
    }
  }, [collapsedHeight, contentRef.current?.offsetHeight]);

  useEffect(() => {
    if (defaultContent != null && !editing) {
      setContent(defaultContent);
    }
  }, [defaultContent, editing]);

  return (
    <Box sx={sx}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '22px',
        }}>
          {title}
        </Typography>
        
        {infoContent != null &&
          <Tooltip
            title={infoContent}>
            <InfoRoundedIcon sx={{fontSize: '14px'}} />
          </Tooltip>
        }

        {collapsable &&
          <Button
            size='small'
            disabled={editing}
            onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Read less' : 'Read more'}
          </Button>
        }
      </Box>
      <Collapse
        sx={{
          cursor: collapsable && !expanded ? 'pointer' : undefined,
          paddingTop: '8px',
        }}
        in={expanded}
        collapsedSize={`${collapsedHeight}px`}
        onClick={collapseClicked}>
        {editing ? (
          <TextField
            multiline
            fullWidth
            value={content}
            onChange={e => setContent(e.target.value)} />
        ) : (
          <Typography
            ref={r => contentRef.current = r as any}
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              whiteSpace: 'pre-wrap',
              fontStyle: content.length === 0 ? 'italic' : undefined,
            }}>
            {content.length > 0 ? content : description}
          </Typography>
        )}
      </Collapse>
      {editable &&
        <Box sx={{
          display: 'flex',
          paddingTop: '12px',
          gap: '12px',
        }}>
          {editing ? (
            <>
              <LoadingButton
                variant='contained'
                size='small'
                disabled={content === defaultContent}
                loading={loading}
                onClick={saveClicked}>
                {'Save'}
              </LoadingButton>
              <Button
                variant='text'
                size='small'
                onClick={cancelClicked}>
                {'Cancel'}
              </Button>
            </>
          ) : (
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              onClick={editClicked}>
              {'Edit'}
            </Button>
          )}
        </Box>
      }
    </Box>
  );
}
