export const brokerSubscriptionFeatures = [
  { feature: 'Lender Search', basic: true, pro: true },
  { feature: 'Lender Contact Information', basic: false, pro: true },
  { feature: 'Lender Tracking Software', basic: false, pro: true },
  { feature: 'Post Lender Ratings/Reviews', basic: true, pro: true },
  { feature: 'Quote Matrix Organizer', basic: false, pro: true },
  { feature: 'Desktop Diligence Workspace', basic: false, pro: true },
  { feature: 'Free Capital Markets Feedback', basic: false, pro: true },
  { feature: 'Co-Brokering Functionality', basic: true, pro: true },
  { feature: 'Post Deals to LP/Pref Equity Marketplace', basic: true, pro: true },
];
