import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { accountsApiSlice } from "./accounts-api";
import { baseUrls, createBaseQuery } from "./apiHelper";


export const paymentsApiSlice = createApi({
  reducerPath: 'api-payments',
  tagTypes: [],
  baseQuery: createBaseQuery(baseUrls.payments),
  endpoints: (build) => ({
    checkout: build.mutation<{url: string}, {
      product: 'broker-subscription-basic' | 'broker-subscription-pro',
      cancelPath: string,
      redirectPath: string,
    }>({
      query: ({product, cancelPath, redirectPath}) => {
        return {
          url: '/checkout',
          method: 'POST',
          body: {
            product,
            cancelPath,
            redirectPath,
          }
        }
      },
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        const accountPatchResult = dispatch(
          accountsApiSlice.util.updateQueryData('getCurrentAccount', undefined, (draft) => {
            draft.isOnboarding = false;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          accountPatchResult.undo();
        }
      }
    }),
    changeSubscription: build.mutation<{messsage: string, status: 'success'}, {product: 'broker-subscription-pro'}>({
      
      query: ({product}) => {
        return {
          url: '/subscriptions/change',
          method: 'POST',
          body: {
            product
          }
        };
      }
    })
  }),
});

export const {
  useCheckoutMutation,
  useChangeSubscriptionMutation,
} = paymentsApiSlice;
