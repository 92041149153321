import { useState, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Logo from '../images/light-creed-logo.png';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { loggedOut } from '../features/auth-slice';
import { useDefaultPath, usePopoverContext } from '../utils/hooks';
import { clearChatState, closeChat, showChat } from '../features/messaging-slice';
import { Alert, Avatar, Badge, Button, Divider, Drawer, IconButton, Link, List, ListItem, ListItemButton, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { paymentsApiSlice } from '../features/payments-api';
import { notificationsApiSlice, useGetNotificationsQuery } from '../features/notifications-api';
import { dealsApiSlice } from '../features/deals-api';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import { accountsApiSlice, useGetCurrentAccountQuery, useGetCurrentAccountTeamQuery, useGetCurrentUserQuery, useUpdateCurrentUserMutation } from '../features/accounts-api';
import { invitationsApiSlice } from '../features/invitations-api';
import { messagingApiSlice } from '../features/messaging-api';
import { IAccount } from '../schemas';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import UpgradeSubscriptionDialog from './Dialogs/UpgradeSubscriptionDialog';


export interface HeaderProps {
  showBanners?: boolean;
}

export default function Header(props: HeaderProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const defaultPath = useDefaultPath();

  const {
    props: purchaseSubscriptionDialogProps,
    showDialog: showPurchaseSubscription,
  } = usePopoverContext();

  const { shouldBeLoggedIn } = useAppSelector(state => state.auth);
  const chatState = useAppSelector(state => state.chat);
  const isLoading = useAppSelector(state => state.loading.isLoading);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [profileAnchorElem, setProfileAnchorElem] = useState<HTMLElement | undefined>();

  const { data: account } = useGetCurrentAccountQuery(undefined, { skip: !shouldBeLoggedIn });
  const { data: user } = useGetCurrentUserQuery(undefined, { skip: !shouldBeLoggedIn });
  const { data: team } = useGetCurrentAccountTeamQuery();
  const { data: notifications } = useGetNotificationsQuery(undefined, { skip: !shouldBeLoggedIn, pollingInterval: 10_000 });

  const [updateCurrentUser] = useUpdateCurrentUserMutation();

  const creedAccountId = account?.creedContact?.accountId;
  
  const routes = useMemo(() => {
    if (account?.status !== 'approved') {
      return [];
    }

    const allRoutes: {path: string, title: string, badge?: () => any}[] = [
      {
        path: '/home',
        title: 'Home',
        badge: () => notifications?.filter(n => !n.isRead).length,
      },
    ];

    if (account.currentSubscription?.isActive) {
      switch (account?.type) {
        case 'sponsor':
          allRoutes.push({
            path: '/deals',
            title: 'My Projects',
          });
          allRoutes.push({
            path: '/investor-directory',
            title: 'Investor Directory',
          });
          allRoutes.push({
            path: '/shared-deals',
            title: 'Shared with Me',
          });
          allRoutes.push({
            path: '/interest-rates',
            title: 'Interest Rates',
          });
          break;
  
        case 'broker':
          if (account.currentSubscription?.type === 'broker-basic' || account.currentSubscription?.type === 'broker-pro') {
            allRoutes.push({
              path: '/deals',
              title: 'My Projects',
            });
            allRoutes.push({
              path: '/investor-directory',
              title: 'Investor Directory',
            });
            allRoutes.push({
              path: '/shared-deals',
              title: 'Shared with Me',
            });
            allRoutes.push({
              path: '/interest-rates',
              title: 'Interest Rates',
            });
          }
          break;
          
        case 'investor':
          allRoutes.push({
            path: '/equity-deals',
            title: 'Equity Marketplace',
          });
          allRoutes.push({
            path: '/shared-deals',
            title: 'My Projects',
          });
          allRoutes.push({
            path: '/sponsor-directory',
            title: 'Sponsor Directory',
          });
          allRoutes.push({
            path: '/interest-rates',
            title: 'Interest Rates',
          });
          break;
  
        case 'lender':
          allRoutes.push({
            path: '/shared-deals',
            title: 'Shared with Me',
          });
          allRoutes.push({
            path: '/interest-rates',
            title: 'Interest Rates',
          });
          break;
      }
    }

    return allRoutes;
  }, [account, notifications]);

  const allAccounts = useMemo(() => {
    const accounts = [...user?.allAccounts ?? []];
    accounts.sort((a, b) => {
      if (a.level === b.level) {
        return Date.parse(a.createdDt) - Date.parse(b.createdDt);
      } else if (a.level === 'individual') {
        return -1;
      } else {
        return 1;
      }
    });

    return accounts;
  }, [user?.allAccounts]);

  const unreadCREEDMessages = useMemo(() => {
    return (notifications?.filter(n => {
      return n.senderAccountId === creedAccountId
        && n.type === 'message'
        && !n.isRead;
    }) ?? []).length;
  }, [notifications, creedAccountId]);

  const chatWithCREEDOpen = useMemo(() => {
    return chatState.windows.find(w => w.key === 'creed') != null;
  }, [chatState.windows]);

  const routeClicked = useCallback((route: typeof routes[number]) => {
    setDrawerOpen(false);
    navigate(route.path);
  }, [setDrawerOpen, navigate]);

  const chatWithCREEDClicked = useCallback(() => {
    setDrawerOpen(false);

    if (!creedAccountId) {
      return;
    }

    if (chatWithCREEDOpen) {
        dispatch(closeChat({ key: 'creed' }));
    } else {
      dispatch(showChat({
        key: 'creed',
        accountIds: [creedAccountId],
        windowTitle: 'CREED Team',
        closable: true,
      }));
    }
  }, [creedAccountId, chatWithCREEDOpen, dispatch]);

  const profileAvatarClicked = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setProfileAnchorElem(event.currentTarget);
  }, [setProfileAnchorElem]);

  const organizationClicked = useCallback(() => {
    navigate('/organization');
    setProfileAnchorElem(undefined);
  }, [navigate, setProfileAnchorElem]);

  const profileClicked = useCallback(() => {
    navigate('/profile');
    setProfileAnchorElem(undefined);
  }, [navigate, setProfileAnchorElem]);

  const teamMembersClicked = useCallback(() => {
    navigate('/team');
    setProfileAnchorElem(undefined);
  }, [navigate, setProfileAnchorElem]);

  const switchAccountClicked = useCallback(async (account: IAccount) => {
    if (user == null) {
      return;
    }

    await updateCurrentUser({ accountId: account._id }).unwrap();
    
    navigate('/home');

    setProfileAnchorElem(undefined);
  }, [updateCurrentUser, user, navigate, setProfileAnchorElem]);

  const logoutClicked = useCallback(async () => {
    await Auth.signOut();
    dispatch(clearChatState());
    navigate('/login');
    dispatch(loggedOut());
    dispatch(accountsApiSlice.util.resetApiState());
    dispatch(dealsApiSlice.util.resetApiState());
    dispatch(invitationsApiSlice.util.resetApiState());
    dispatch(messagingApiSlice.util.resetApiState());
    dispatch(notificationsApiSlice.util.resetApiState());
    dispatch(paymentsApiSlice.util.resetApiState());
    setProfileAnchorElem(undefined);
  }, [navigate, dispatch, setProfileAnchorElem]);

  // const subscribeClicked = useCallback(async (event: React.SyntheticEvent) => {
  //   event.preventDefault();

  //   const response = await checkout({product: 'broker-subscription-pro'}).unwrap();
  //   window.location.assign(response.url);
  // }, [checkout]);

  return (
    <Box component='div' sx={{
      width: '100%',
    }}>
      <AppBar elevation={0} position='static'>
        <Container
          sx={{
            width: isMobile ? '90vw' : '80vw'
          }}
          disableGutters
          maxWidth={false}>
          <Toolbar disableGutters>
            <a href={defaultPath} style={{lineHeight: 0}}>
              <img alt='CREED' src={Logo} style={{ width: 100 }} />
            </a>
            
            {isMobile ? (
              <Box sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}>
                {shouldBeLoggedIn &&
                  <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                    <MenuIcon />
                  </IconButton>
                }
              </Box>
            ) : (
                <>
                  <Box sx={{
                    flexGrow: 1,
                    display: { xs: 'flex' },
                    marginLeft: '48px' }}>
                    {shouldBeLoggedIn &&
                      routes.map((r, index) => (
                        <Badge
                          key={r.path}
                          color='primary'
                          invisible={r.badge == null || !r.badge()}
                          badgeContent={r.badge != null ? r.badge() : undefined}>
                          <Button
                            sx={{
                              marginLeft: index > 0 ? '16px' : 0,
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '17px',
                              background: location.pathname === r.path ? '#3333330D' : undefined,
                            }}
                            variant='text'
                            disabled={!account?.profileComplete}
                            onClick={() => routeClicked(r)}>
                            {r.title}
                          </Button>
                        </Badge>
                      ))
                    }
                  </Box>

                  <Box sx={{ display: 'flex', flexGrow: 0, alignItems: 'center' }}>
                    {shouldBeLoggedIn &&
                      <>
                        {account?.currentSubscription?.isActive && creedAccountId != null && account?.status === 'approved' &&
                          <Badge
                            sx={{
                              marginRight: '28px',
                            }}
                            invisible={unreadCREEDMessages === 0}
                            color='primary'
                            variant='dot'>
                            <Button
                              sx={{
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '17px',
                                background: chatWithCREEDOpen ? '#3333330D' : undefined,
                              }}
                              variant='text'
                              onClick={chatWithCREEDClicked}>
                              {'Chat with CREED'}
                            </Button>
                          </Badge>
                        }

                        <Avatar
                          sx={{
                            width: '32px',
                            height: '32px',
                            ':hover': {
                              cursor: 'pointer' 
                            }
                          }}
                          alt='profile'
                          src={account?.profileImageUrl}
                          onClick={profileAvatarClicked} />
                      </>
                    
                  }
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      {isLoading &&
        <Box sx={{ 
          position: 'absolute',
          bottom: -4,
          width: '100%', 
          '& .MuiLinearProgress-root': {
            backgroundColor: '#DDCFB2'
          },
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#D9B361'
          }
        }}>
          <LinearProgress />
        </Box>
      }

      {props.showBanners !== false && account != null &&
        <>
          {account.type === 'broker' && account.currentSubscription?.isActive && account.currentSubscription.type === 'broker-basic' &&
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                width: '100%',
                padding: 1,
                cursor: 'pointer',
                background: '#333333DD',
              }}
              onClick={() => showPurchaseSubscription()}>
              <RocketLaunchIcon sx={{
                fontSize: '12px',
                color: 'white',
              }} />

              <Typography sx={{
                fontWeight: '400',
                fontSize: '12px',
                color: 'white',
              }}>
                {'Upgrade to CREED Professional'}
              </Typography>
            </Box>
          }

          {!account.profileComplete && location.pathname !== '/organization' &&
            <Alert
              severity='error'>
              {'Please complete your organization profile before using the rest of the platform. '}
              <Link
                href='/organization'>
                {'Click here to edit your profile'}
              </Link>
            </Alert>
          }
        </>
      }

      {!isMobile &&
        <Menu
          sx={{
            zIndex: 10001
          }}
          open={profileAnchorElem != null}
          anchorEl={profileAnchorElem}
          onClose={() => setProfileAnchorElem(undefined)}>
            <MenuItem onClick={organizationClicked}>
              <Avatar
                sx={{
                  width: '24px',
                  height: '24px',
                }}
                alt='profile'
                src={account?.profileImageUrl} />

              <Typography variant='menuItem'>
                {'Organization profile'}
              </Typography>
            </MenuItem>

            <MenuItem onClick={profileClicked}>
              <PersonIcon sx={{fontSize: '16px', color: '#33333380'}} />
              <Typography>
                {'My profile'}
              </Typography>
            </MenuItem>

            {account?.status === 'approved'
              && (account.type !== 'broker' || (account.type === 'broker' && account.currentSubscription?.isActive && ['broker-basic', 'broker-pro'].includes(account.currentSubscription.type as string))) &&
              team != null &&
                <MenuItem onClick={teamMembersClicked}>
                  <GroupIcon sx={{fontSize: '16px', color: '#33333380'}} />
                  <Typography>
                    {'Team members'}
                  </Typography>
                </MenuItem>
            }
            <Divider />

          {allAccounts.length > 1 &&
            <Box>
              <Box sx={{
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingTop: '8px',
                paddingBottom: '8px',
              }}>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: 'gray',
                }}>
                  {'Switch organization'}
                </Typography>
              </Box>

              {allAccounts.map(acct => (
                <MenuItem
                  key={acct._id}
                  disabled={acct._id === account?._id}
                  onClick={() => switchAccountClicked(acct)}>
                  <Avatar
                    sx={{
                      width: '24px',
                      height: '24px',
                    }}
                    alt='profile'
                    src={acct.profileImageUrl} />
                  <Typography>
                    {`${acct.name}${acct._id === account?._id ? ' (Current)' : ''}`}
                  </Typography>
                </MenuItem>
              ))}
              
              <Divider />
            </Box>
          }

          <MenuItem onClick={logoutClicked}>
            <LogoutIcon sx={{fontSize: '16px', color: '#33333380'}} />

            <Typography variant='menuItem'>
              {'Log out'}
            </Typography>
          </MenuItem>
        </Menu>
      }

      {isMobile &&
        <Drawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          anchor='left'>
          <List sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '58px',
            justifyContent: 'space-between',
          }}>
            <Box>
              {routes.map((route, index) => (
                <ListItem key={route.path}>
                    <ListItemButton sx={{
                      borderRadius: '8px',
                      background: location.pathname === route.path ? '#3333330D' : undefined,
                    }}
                    onClick={() => routeClicked(route)}>
                      <Typography sx={{
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '17px',
                      }}>
                        {route.title}
                      </Typography>

                      <Badge
                        sx={{
                          display: 'flex',
                          flex: 1,
                        }}
                        color='primary'
                        invisible={route.badge == null || !route.badge()}
                        badgeContent={route.badge != null ? route.badge() : undefined} />
                    </ListItemButton>
                </ListItem>
              ))}
            </Box>

            <Box>
              {account?.status === 'approved' &&
                <>
                  <ListItem>
                    <ListItemButton sx={{
                      borderRadius: '8px',
                      background: chatWithCREEDOpen ? '#3333330D' : undefined,
                    }}
                    onClick={chatWithCREEDClicked}>
                      <Typography sx={{
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '17px',
                      }}>
                        {'Chat with CREED'}
                      </Typography>
                    </ListItemButton>
                  </ListItem>

                  <ListItem>
                    <ListItemButton sx={{
                      borderRadius: '8px',
                      background: location.pathname === '/organization' ? '#3333330D' : undefined,
                    }}
                    onClick={() => navigate('/organization')}>
                      <Typography sx={{
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '17px',
                      }}>
                        {'Organization profile'}
                      </Typography>
                    </ListItemButton>
                  </ListItem>

                  {team != null &&
                    <ListItem>
                      <ListItemButton sx={{
                        borderRadius: '8px',
                        background: location.pathname === '/team' ? '#3333330D' : undefined,
                      }}
                      onClick={() => navigate('/team')}>
                        <Typography sx={{
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '17px',
                        }}>
                          {'Team members'}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  }
                </>
              }

              <ListItem>
                <ListItemButton sx={{
                  borderRadius: '8px',
                }}
                onClick={logoutClicked}>
                  <Typography sx={{
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '17px',
                  }}>
                    {'Logout'}
                  </Typography>
                </ListItemButton>
              </ListItem>
            </Box>
          </List>
        </Drawer>
      }

      <UpgradeSubscriptionDialog
        {...purchaseSubscriptionDialogProps} />
    </Box>
  )
}
