import { useCallback, useMemo } from 'react';
import { DealLenderSource, IDeal, IDealContactedLender } from '../../../schemas';
import { useSearchLendersQuery } from '../../../features/deals-api';
import { Avatar, Button, Box, Typography, Chip, Tooltip, IconButton, Badge } from '@mui/material';
import dayjs from 'dayjs';
import { wrapColumnsInTooltips } from '../../../utils/componentUtils';
import { usePopoverContext } from '../../../utils/hooks';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useAppDispatch } from '../../../app/hooks';
import { showChatForThread } from '../../../features/messaging-slice';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import LenderReviewsDialog from '../Dialogs/LenderReviewsDialog';
import LenderFeedbackDialog from '../Dialogs/LenderFeedbackDialog';
import { DataGridPro, GridColDef, GridToolbarContainer } from '@mui/x-data-grid-pro';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import SponsorRecordLenderFeedbackDialog from '../Dialogs/SponsorRecordLenderFeedbackDialog';
import SponsorRecordQuoteDialog from '../Dialogs/SponsorRecordQuoteDialog';
import { useGetLenderMetadataForLendersQuery } from '../../../features/lenders-api';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { downloadStringAsFile, generateCSVString } from '../../../utils/utils';
import { useGetCurrentAccountQuery } from '../../../features/accounts-api';



interface LenderOutreachToolbarProps {
  lenders?: IDealContactedLender[];
}

function LenderOutreachToolbar({lenders}: LenderOutreachToolbarProps) {
  
  const downloadCSVClicked = useCallback(() => {
    if (lenders == null) {
      return;
    }

    const csvString = generateCSVString({
      columns: [
        {key: 'lenderName', name: 'Lender Name'},
        {key: 'dateContacted', name: 'Date Contacted'},
        {key: 'status', name: 'Status'},
        {key: 'mostRecent', name: 'Most Recent'},
        {key: 'feedback', name: 'Feedback'},
        {key: 'feedbackDt', name: 'Feedback Date'},
        {key: 'quoteSubmitted', name: 'Quote Submitted'},
      ],
      rows: lenders.flatMap(lender => {
        if (lender.feedback != null && lender.feedback.length > 0) {
          const mostRecent = lender.feedback[lender.feedback.length - 1]?.createdDt;

          return lender.feedback?.map(feedback => {
            return {
              lenderName: lender.name,
              dateContacted: dayjs(lender.contactedDt).format('MM/DD/YYYY'),
              status: lender.status === 'accepted' ? 'Interested' : lender.status === 'rejected' ? 'Declined' : 'Pending',
              mostRecent: mostRecent != null ? dayjs(mostRecent).format('MM/DD/YYYY') : '',
              feedback: feedback.message,
              feedbackDt: dayjs(feedback.createdDt).format('MM/DD/YYYY'),
              quoteSubmitted: lender.quoteOptions != null && lender.quoteOptions.length > 0 && lender.quoteOptions.some(o => o.quotes != null && o.quotes.length > 0),
            };
          })
        } else {   
          return {
            lenderName: lender.name,
            dateContacted: dayjs(lender.contactedDt).format('MM/DD/YYYY'),
            status: lender.status === 'accepted' ? 'Interested' : lender.status === 'rejected' ? 'Declined' : 'Pending',
            mostRecent: '',
            feedback: '',
            feedbackDt: '',
            quoteSubmitted: lender.quoteOptions != null && lender.quoteOptions.length > 0 && lender.quoteOptions.some(o => o.quotes != null && o.quotes.length > 0),
          };
        }
      })
    });

    downloadStringAsFile(csvString, 'lender-outreach.csv', 'text/csv');
  }, [lenders]);
  
  return (
    <GridToolbarContainer>
      <Button
        sx={{
          gap: 1,
        }}
        size='small'
        onClick={downloadCSVClicked}>
        <FileDownloadOutlinedIcon sx={{fontSize: '18px'}} />
        {'Download as CSV'}
      </Button>
    </GridToolbarContainer>
  );
}

interface Props {
  deal?: IDeal;
}

export default function LenderSearchContactedDataGrid({deal}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const {
    props: lenderFeedbackDialogProps,
    showDialog: showLenderFeedback,
    currentData: lenderFeedbackLender,
  } = usePopoverContext<IDealContactedLender>();

  const {
    props: lenderReviewsDialogProps,
    showDialog: showLenderReviews,
    currentData: lenderReviewsLender,
  } = usePopoverContext<string>();

  const {
    props: recordLenderFeedbackDialogProps,
    showDialog: showRecordLenderFeedback,
    currentData: recordLenderFeedbackLenderName,
  } = usePopoverContext<{name: string, logoUrl?: string}>();

  const {
    props: recordLenderQuoteDialogProps,
    showDialog: showRecordLenderQuote,
    currentData: recordLenderQuoteLenderName,
  } = usePopoverContext<string>();

  const {data: account} = useGetCurrentAccountQuery();
  const {data: contactedLenders} = useSearchLendersQuery({ dealId: deal?._id!, filter: 'contacted' }, { skip: deal?._id == null });
  const {data: lenderMetadata} = useGetLenderMetadataForLendersQuery({ lenderNames: deal?.contactedLenders?.map(l => l.name)! }, { skip: deal?.contactedLenders == null });

  const chatLenderClicked = useCallback((lender: IDealContactedLender) => {
    if (lender.threadId == null) {
      return;
    }
    
    dispatch(showChatForThread({
      key: 'chat',
      threadId: lender.threadId,
      windowSubtitle: lender.name,
      closable: true,
    }));
  }, [dispatch]);

  const sortedContactedLenders = useMemo(() => {
    let accepted = deal?.contactedLenders?.filter(l => l.status === 'accepted') ?? []
    accepted = [
      ...accepted.filter(l => l.feedback != null && l.feedback.length > 0).sort((l1, l2) => l1.name.localeCompare(l2.name)),
      ...accepted.filter(l => l.feedback == null || l.feedback.length === 0).sort((l1, l2) => l1.name.localeCompare(l2.name)),
    ];

    let pending = deal?.contactedLenders?.filter(l => l.status === 'pending') ?? [];
    pending = [
      ...pending.filter(l => l.feedback != null && l.feedback.length > 0).sort((l1, l2) => l1.name.localeCompare(l2.name)),
      ...pending.filter(l => l.feedback == null || l.feedback.length === 0).sort((l1, l2) => l1.name.localeCompare(l2.name)),
    ];

    let rejected = deal?.contactedLenders?.filter(l => l.status === 'rejected') ?? [];
    rejected = [
      ...rejected.filter(l => l.feedback != null && l.feedback.length > 0).sort((l1, l2) => l1.name.localeCompare(l2.name)),
      ...rejected.filter(l => l.feedback == null || l.feedback.length === 0).sort((l1, l2) => l1.name.localeCompare(l2.name)),
    ];

    return [
      ...accepted,
      ...pending,
      ...rejected,
    ];
  }, [deal?.contactedLenders]);

  const columns: GridColDef<IDealContactedLender>[] = useMemo(() => {
    const columns: (GridColDef<IDealContactedLender> | null)[] = [
      {
        field: 'source',
        headerName: 'Source',
        width: 70,
        valueFormatter: (params) => (params.value as string).toUpperCase(),
        renderCell: (params) => {
          let title = '';
          switch (params.value as DealLenderSource) {
            case 'ca':
              title = '(CREED Add) This lender was added by CREED';
              break;
            case 'cia':
              title = '(CREED Individual Add) This lender was added by CREED and it did not already exist in CREED\'s system';
              break;
            case 'sa':
              title = '(Sponsor Add) This lender was added by you';
              break;
            case 'sia':
              title = '(Sponsor Individual Add) This lender was was added by you and it did not already exist in CREED\'s system';
              break;
          }
          
          return (
            <Tooltip
              title={title}
              enterDelay={1000}>
              <div style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
                {params.formattedValue}
              </div>
            </Tooltip>
          );
        },
      },
      {
        field: 'Logo',
        headerName: '',
        width: 52,
        valueGetter: (params) => {
          const lender = contactedLenders?.find(l => l.Lender === params.row.name);
          return lender?.LogoUrl as string;
        },
        renderCell: (params) => (
          <Avatar
            sx={{
              width: '36px',
              height: '36px',
            }}
            variant='rounded'
            src={params.value} />
        )
      },
      {
        field: 'recipientName',
        headerName: 'Lender Name',
        flex: 1,
        valueGetter: (params) => params.row.name,
      },
      {
        field: 'contactDate',
        headerName: 'Date Contacted',
        valueGetter: (params) => params.row.contactedDt,
        valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY'),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        renderCell: (params) => {
          let title = '';
          switch (params.value) {
            case 'accepted':
              title = 'The lender has responded positively to this project';
              break;
            case 'rejected':
              title = 'The lender has declined this project. Please click the "View Feedback" button for more information';
              break;
            default:
              title = 'The lender has not yet responded';
          }
          return (
            <Tooltip
              title={title}
              enterDelay={1000}>
              <Chip
                color={params.value === 'accepted' ? 'success' : params.value === 'rejected' ? 'error' : 'info'}
                label={params.value === 'accepted' ? 'Interested' : params.value === 'rejected' ? 'Declined' : 'Pending'} />
            </Tooltip>
          );
        }
      },
      {
        field: 'mostRecentContact',
        headerName: 'Most Recent Contact',
        valueGetter: (params) => {
          if (params.row.feedback != null) {
            return params.row.feedback[params.row.feedback.length - 1]?.createdDt;
          }

          return undefined;
        },
        valueFormatter: (params) => params.value != null ? dayjs(params.value).format('MM/DD/YYYY') : '-',
      },
      {
        field: 'feedback',
        headerName: 'Feedback',
        width: 140,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Badge
            color='warning'
            variant='dot'
            invisible={!Boolean(params.row.feedback)}>
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              onClick={() => showLenderFeedback(params.row)}>
              {'View Feedback'}
            </Button>
          </Badge>
        )
      },
      {
        field: 'quoteSubmitted',
        headerName: 'Quote Submitted',
        width: 100,
        valueGetter: (params) => {
          const quoteOptions = params.row.quoteOptions;
          if (quoteOptions == null || quoteOptions.length === 0) {
            return false;
          }

          return quoteOptions.some(o => o.quotes != null && o.quotes.length > 0);
        },
        renderCell: (params) => (
          <Chip
            color={params.value ? 'success' : 'error'}
            label={params.value ? 'Yes' : 'No'} />
        )
      },
      deal?.whiteGlove?.status === 'enabled' ? {
        field: 'chat',
        headerName: 'Click here to chat',
        width: 100,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
          if (params.row.threadId == null) {
            return null;
          }

          return (
            <IconButton
              disabled={params.row.threadId == null}
              onClick={() => chatLenderClicked(params.row)}>
              <ChatBubbleOutlineIcon 
                sx={{
                  color: 'inherit',
                }}
                fontSize='small' />
            </IconButton>
          );
        }
      } : null,
      deal?.whiteGlove?.status !== 'enabled' || (deal?.whiteGlove?.status === 'enabled' && account?.isAdmin) ? {
        field: 'lenderFeedback',
        headerName: 'Lender Feedback',
        width: 75,
        renderCell: (params) => (
          <Tooltip
            title='Record feedback from the lender'
            enterDelay={1000}>
            <IconButton onClick={() => showRecordLenderFeedback({name: params.row.name, logoUrl: contactedLenders?.find(l => l.Lender === params.row.name)?.LogoUrl as string})}>
              <AddCommentOutlinedIcon
                sx={{
                  color: 'inherit'
                }}
                fontSize='small' />
            </IconButton>
          </Tooltip>
        )
      } : null,
      deal?.whiteGlove?.status !== 'enabled' || (deal?.whiteGlove?.status === 'enabled' && account?.isAdmin) ? {
        field: 'lenderQuote',
        headerName: 'Lender Quotes',
        width: 75,
        renderCell: (params) => (
          <Tooltip
              title={`Record a quote from the lender${params.row.status !== 'accepted' ? '. A quote cannot be recorded until this lender has a status of Interested. Click the Record Lender Feedback button for this lender to set its status to Interested.' : ''}`}
              enterDelay={1000}>
              <span>
              <IconButton
                disabled={params.row.status !== 'accepted'}
                onClick={() => showRecordLenderQuote(params.row.name)}>
                <RequestQuoteOutlinedIcon
                  sx={{
                    color: 'inherit'
                  }}
                  fontSize='small' />
              </IconButton>
              </span>
            </Tooltip>
        )
      } : null,
      {
        field: 'info',
        headerName: 'Ratings / Reviews',
        disableColumnMenu: true,
        sortable: false,
        width: 75,
        renderCell: (params) => {
          if (params.row.source !== 'ca' && params.row.source !== 'sa') {
            return null;
          }
          
          return (
            <Badge
              invisible={!Boolean(lenderMetadata?.find(m => m.lenderName === params.row.name)?.reviews)}
              color='warning'
              overlap='circular'
              variant='dot'>
              <IconButton
                disabled={params.row.source !== 'ca' && params.row.source !== 'sa'}
                onClick={() => showLenderReviews(params.row.name)}>
                <NotesRoundedIcon
                  sx={{
                    color: 'inherit',
                  }}
                  fontSize='small' />
              </IconButton>
            </Badge>
          );
        }
      },
    ];

    return wrapColumnsInTooltips(columns.filter(c => c != null) as GridColDef<IDealContactedLender>[]);
  }, [account?.isAdmin, chatLenderClicked, contactedLenders, deal?.whiteGlove?.status, lenderMetadata, showLenderFeedback, showLenderReviews, showRecordLenderFeedback, showRecordLenderQuote]);

  return (
    <Box>
      <DataGridPro
        sx={{
          fontWeight: '400',
          fontSize: '12px',
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
          },
          '& .MuiDataGrid-columnHeader': {
            // Forced to use important since overriding inline styles
            height: 'unset !important'
          },
          '& .MuiDataGrid-columnHeaders': {
            // Forced to use important since overriding inline styles
            maxHeight: '168px !important'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          }
        }}
        columns={columns}
        rows={sortedContactedLenders}
        getRowId={r => r._id!}
        columnHeaderHeight={50}
        autoHeight
        hideFooter={sortedContactedLenders.length <= 100}
        disableColumnSelector
        isRowSelectable={() => false}
        initialState={{
          pinnedColumns: {
            right: ['actions']
          }
        }}
        slotProps={{
          toolbar: {
           lenders: sortedContactedLenders, 
          }
        }}
        slots={{
          toolbar: LenderOutreachToolbar,
          noRowsOverlay: () => (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '22px',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '12px',
              }}>
                {'No lenders have been contacted.'}
              </Typography>
            </Box>
          ),
        }} />

      <LenderFeedbackDialog
        {...lenderFeedbackDialogProps}
        deal={deal}
        lender={lenderFeedbackLender} />

      <LenderReviewsDialog
        {...lenderReviewsDialogProps}
        lenderName={lenderReviewsLender} />

      <SponsorRecordLenderFeedbackDialog
        {...recordLenderFeedbackDialogProps}
        deal={deal}
        lenderName={recordLenderFeedbackLenderName?.name}
        lenderLogoUrl={recordLenderFeedbackLenderName?.logoUrl} />

      <SponsorRecordQuoteDialog
        {...recordLenderQuoteDialogProps}
        deal={deal}
        lenderName={recordLenderQuoteLenderName} />
    </Box>
  );
}
