import { useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { IAccount, IDeal, IInvestorDealResponse, IUser } from '../../../schemas';
import { useGetEquityDealReviewForDealQuery, useGetInvestorDealResponsesQuery, useGetSharedDealsForDealQuery } from '../../../features/deals-api';
import CommentRow from '../../CommentRow';
import Card from '../../Card';
import PublishEquityDealDialog from '../Dialogs/PublishEquityDealDialog';
import { usePopoverContext } from '../../../utils/hooks';
import EquityDealReviewStatusChip from '../Chips/EquityDealReviewStatusChip';
import SponsorUnpublishEquityDealDialog from '../Dialogs/SponsorUnpublishEquityDealDialog';
import InvestorStatusChip from '../Chips/InvestorStatusChip';
import LoadingButton from '../../LoadingButton';
import EquityDealInvestorResponseRow from '../EquityDealInvestorResponseRow';
import { useAppSelector } from '../../../app/hooks';


interface Props {
  readonly account?: IAccount;
  readonly user?: IUser;
  readonly deal?: IDeal;
}

export default function EquitySponsorView({account, deal}: Props): JSX.Element {

  const {
    props: publishDealDialogProps,
    showDialog: showPublishDeal,
  } = usePopoverContext();

  const {
    props: unpublishDealDialogProps,
    showDialog: showUnpublishDeal,
  } = usePopoverContext();

  const {windows: chatWindows} = useAppSelector(s => s.chat);

  const [unreadMessages, setUnreadMessages] = useState<Record<string, number>>({});

  const {data: dealReview} = useGetEquityDealReviewForDealQuery({ dealId: deal?._id! }, { skip: deal?._id == null, pollingInterval: 10_000 });
  const {data: investorSharedDeals, isLoading: isInvestorSharedDealsLoading} = useGetSharedDealsForDealQuery({
    dealId: deal?._id!,
    accountType: 'investor'
  }, {
    skip: deal?._id == null || account?._id !== deal.accountId,
    pollingInterval: 5000,
  });
  const {data: investorResponses, isLoading: isInvestorResponsesLoading} = useGetInvestorDealResponsesQuery({ dealId: deal?._id! }, { skip: deal?._id == null, pollingInterval: 10_000 });

  const sortedInvestorResponses: IInvestorDealResponse[] = useMemo(() => {
    const responses = [...investorResponses ?? []];
    responses.sort((a, b) => Date.parse(a.createdDt) - Date.parse(b.createdDt));
    return responses;
  }, [investorResponses]);

  const acceptedInvestorSharedDeals = useMemo(() => {
    return investorSharedDeals?.filter(sd => sd.investorStatus === 'accepted');
  }, [investorSharedDeals]);

  const chatWindowOpen = useMemo(() => {
    return chatWindows.some(w => w.key === 'chat' && investorSharedDeals?.some(sd => sd.threadId === w.threadId));
  }, [chatWindows, investorSharedDeals]);

  const hasUnreadMessages = useMemo(() => {
    return Object.keys(unreadMessages).reduce((ret, key) => ret || (unreadMessages[key] ?? 0) > 0, false);
  }, [unreadMessages]);

  const publishable = dealReview == null
    || dealReview.status === 'rejected'
    || (dealReview.status === 'approved' && deal?.privacy === 'private');

  const publishProjectClicked = useCallback(() => {
    if (publishable) {
      showPublishDeal(undefined);
    } else {
      showUnpublishDeal(undefined);
    }
  }, [publishable, showPublishDeal, showUnpublishDeal]);

  const setUnreadMessagesForInvestor = useCallback((accountId: string, messages: number) => {
    if (!chatWindowOpen || messages === 0) {
      setUnreadMessages(p => ({...p, [accountId]: messages}));
    }
  }, [chatWindowOpen]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}>
      {dealReview?.status === 'pending' &&
        <Box sx={{
          paddingBottom: '32px',
        }}>
          <Alert
            severity='warning'
            icon={false}>
            <Typography>
              {'Your project will be published by the CREED Team. Please wait 24-48 hours for your project to be published to the Equity Marketplace.'}
            </Typography>
          </Alert>
        </Box>
      }

      <Card>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
        }}>
          <Typography sx={{
            fontWeight: '500',
            fontSize: '22px',
          }}>
            {'Project Actions'}
          </Typography>

          {deal != null &&
            <EquityDealReviewStatusChip
              reviewStatus={dealReview?.status}
              dealPrivacy={deal.privacy!} />
          }
        </Box>

        {(account?.type === 'broker' && (deal?.sponsorInfo == null || deal.sponsorInfo.trim().length === 0)) &&
          <Box sx={{
            paddingTop: 2,
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              fontStyle: 'italic',
              color: '#B1060F',
            }}>
              {'Please fill out the Sponsor Information field above in order to publish this project.'}
            </Typography>
          </Box>
        }

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
          paddingTop: '22px',
        }}>
          <Button
            variant='contained'
            disabled={!account?.currentSubscription?.isActive || (account.type === 'broker' && (deal?.sponsorInfo == null || deal.sponsorInfo.trim().length === 0))}
            onClick={publishProjectClicked}>
            {publishable ? 'Publish Project' : 'Unpublish Project'}
          </Button>
        </Box>
      </Card>

      {hasUnreadMessages &&
        <Box sx={{
          paddingTop: 4,
        }}>
          <Alert
            severity='error'>
            {'You have unread messages from investors interested in this project. You can view their responses and chat with them by clicking the "Chat with investor" buttons below.'}
          </Alert>
        </Box>
      }

      <Box sx={{
        paddingTop: 4,
      }}>
        <LoadingButton sx={{
          fontWeight: 'bold',
          fontSize: '13px',
          lineHeight: '16px',
        }}
        loading={isInvestorSharedDealsLoading || isInvestorResponsesLoading}>
          {`Equity Investor Responses (${(acceptedInvestorSharedDeals?.length ?? 0) + (sortedInvestorResponses.length ?? 0)})`}
        </LoadingButton>

        <Box sx={{
          paddingTop: '4px',
        }}>
          {acceptedInvestorSharedDeals?.map(sharedDeal => (
            <EquityDealInvestorResponseRow
              key={sharedDeal._id}
              sharedDeal={sharedDeal}
              setUnreadMessages={messages => setUnreadMessagesForInvestor(sharedDeal.accountId, messages)} />
          ))}

          {sortedInvestorResponses?.map((response, index) => (
            <CommentRow
              key={response._id}
              name={`Investor ${index + 1}`}
              comment={response.comment ?? ''}
              date={response.createdDt}>
              <Box sx={{
                paddingTop: '12px',
              }}>
                <InvestorStatusChip status={response.status as any} />
              </Box>
            </CommentRow>
          ))}
        </Box>
      </Box>

      <PublishEquityDealDialog
        {...publishDealDialogProps}
        deal={deal} />

      <SponsorUnpublishEquityDealDialog
        {...unpublishDealDialogProps}
        deal={deal} />
    </Box>
  );
}
