import React, { useCallback, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ResetPwd from "./containers/routes/ResetPwd";
import Login from "./containers/routes/Login";
import Deals from "./containers/routes/Deals";
import Deal, { loader as dealLoader } from "./containers/routes/Deal";
import NotFound from "./containers/routes/NotFound";
import { useAppDispatch, useAppSelector } from './app/hooks';
import { loggedOut } from './features/auth-slice';
import { Auth } from 'aws-amplify';
import { log } from './utils/jsUtils';
import AcceptDealInvitation from "./containers/routes/AcceptDealInvitation";
import SharedDeals from "./containers/routes/SharedDeals";
import EquityDeals from "./containers/routes/EquityDeals";
import { useGetCurrentAccountQuery, useGetCurrentUserQuery } from "./features/accounts-api";
import mixpanel from "mixpanel-browser";
import Organization from "./containers/routes/Organization";
import Register from "./containers/routes/Register";
import TOS from "./containers/routes/TOS";
import Home from "./containers/routes/Home";
import RegisterComplete from "./containers/routes/RegisterComplete";
import CheckoutComplete from "./containers/routes/CheckoutComplete";
import Index from "./containers/routes/Index";
import InterestRates from "./containers/routes/InterestRates";
import DealDashboard from "./containers/routes/DealDashboard";
import DealDocuments from "./containers/routes/DealDocuments";
import DealDiligenceDocumentRequest from "./containers/Deal/DealDiligenceDocumentRequest";
import TeamMembers from "./containers/routes/TeamMembers";
import UserProfile from "./containers/routes/UserProfile";
import AcceptTeamInvitation from "./containers/routes/AcceptTeamInvitation";
import CreateDeal from "./containers/routes/CreateDeal";
import SponsorDirectory from "./containers/routes/SponsorDirectory";
import AccountStatusPending from "./containers/routes/AccountStatusPending";
import AppRoute from "./containers/AppRoute";
import DealProgressTable from "./containers/routes/DealProgressTable";
import InvestorDirectory from "./containers/routes/InvestorDirectory";
import * as Sentry from '@sentry/react';
import ChoosePlan from "./containers/routes/ChoosePlan";


const router = createBrowserRouter([
  {
    path: '/accept-invitation/:invitationId',
    element: <AppRoute type='unprotected' element={AcceptDealInvitation} />
  }, {
    path: '/accept-team-invitation/:invitationId',
    element: <AppRoute type='unprotected' element={AcceptTeamInvitation} />
  }, {
    path: '/reset-pwd',
    element: <AppRoute type='no-session' element={ResetPwd} />
  }, {
    path: '/tos',
    element: <AppRoute type='unprotected' element={TOS} />,
  }, {
    path: '/',
    element: <AppRoute type='unprotected' element={Index} />,
  }, {
    path: '/account-pending',
    element: <AppRoute type='protected' element={AccountStatusPending} />
  }, {
    path: '/choose-plan',
    element: <AppRoute type='protected' element={ChoosePlan} />
  }, {
    path: '/organization',
    element: <AppRoute type='protected' element={Organization} />
  }, {
    path: '/profile',
    element: <AppRoute type='protected' element={UserProfile} />
  }, {
    path: '/team',
    element: <AppRoute type='protected' element={TeamMembers} />
  }, {
    path: '/home',
    element: <AppRoute type='protected' element={Home} />
  }, {
    path: '/deals',
    element: <AppRoute type='protected' element={Deals} />,
  }, {
    path: '/interest-rates',
    element: <AppRoute type='protected' element={InterestRates} />,
  }, {
    path: '/sponsor-directory',
    element: <AppRoute type='protected' element={SponsorDirectory} />
  }, {
    path: '/investor-directory',
    element: <AppRoute type='protected' element={InvestorDirectory} />
  }, {
    path: '/new-deal',
    element: <AppRoute type='protected' element={CreateDeal} />,
  }, 
  {
    path: '/deal/:dealId/documents/:documentRequestId',
    element: <AppRoute type='protected' element={DealDiligenceDocumentRequest} />,
    loader: dealLoader,
  },
  {
    path: '/deal/:dealId/documents/:documentRequestId/file/:key',
    element: <div />,
    loader: dealLoader,
  },
  {
    path: '/deal/:dealId',
    element: <AppRoute type='protected' element={Deal} />,
    loader: dealLoader,
    children: [
      {
        path: '/deal/:dealId/dashboard',
        loader: dealLoader,
        element: <AppRoute type='protected' element={DealDashboard} />
      },
      {
        path: '/deal/:dealId/progress-table',
        loader: dealLoader,
        element: <AppRoute type='protected' element={DealProgressTable} />
      },
      {
        path: '/deal/:dealId/documents',
        loader: dealLoader,
        element: <AppRoute type='protected' element={DealDocuments} />
      }
    ]
  }, {
    path: '/shared-deals',
    element: <AppRoute type='protected' element={SharedDeals} />
  }, {
    path: '/equity-deals',
    element: <AppRoute type='protected' element={EquityDeals} />
  }, {
    path: '/login',
    element: <AppRoute type='no-session' element={Login} />,
  }, {
    path: '/signup',
    element: <AppRoute type='no-session' element={Register} />,
  }, {
    path: '/signup-complete',
    element: <AppRoute type='no-session' element={RegisterComplete} />,
  }, {
    path: '/checkout-complete',
    element: <AppRoute type='protected' element={CheckoutComplete} />,
  }, {
    path: '*',
    element: <NotFound />
  }
]);


export default function Links() {

  const dispatch = useAppDispatch();

  const {shouldBeLoggedIn} = useAppSelector(s => s.auth);

  const {data: account} = useGetCurrentAccountQuery();
  const {data: user} = useGetCurrentUserQuery();

  const verifySession = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      log('[RouterApp.useEffect] Auth.currentSession: ', session);
      if (typeof session === 'undefined' || !session || !session.isValid) {
        dispatch(loggedOut());
      }
    } catch (e) {
      log('[RouterApp.useEffect] error: ', e);
      dispatch(loggedOut());
    }
  }, [dispatch]);

  useEffect(() => {
    verifySession();
  }, [verifySession]);

  useEffect(() => {
    if (user != null) {
      try {
        mixpanel.identify(user._id);
        mixpanel.people.set({
          $email: user.email,
          $name: `${user.firstName} ${user.lastName}`,
        });
      } catch {}
    }
  }, [user]);

  useEffect(() => {
    if (account != null) {
      try {
        mixpanel.people.set({
          'Account ID': account._id,
          'Account Type': account.type,
        });
      } catch {}
    }
  }, [account]);

  useEffect(() => {
    if (shouldBeLoggedIn && user != null && account != null) {
      Sentry.setUser({
        id: user._id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        accountId: account._id,
        accountName: account.name,
        accountType: account.type,
      });
    } else if (!shouldBeLoggedIn) {
      Sentry.setUser(null);
    }
  }, [shouldBeLoggedIn, user, account]);

  return (
    <RouterProvider router={router} />
  );
}
