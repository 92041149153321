import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetCurrentAccountQuery } from "../features/accounts-api";
import { AccountEquityFeeStructure, AccountType, IAccount, IAccountProfile, IDealInfo } from "../schemas";
import { IProfileField, getProfileFields } from "../types/ProfileFields";
import { getDefaultPathForAccountType } from "./utils";
import SearchField, { fields } from "../types/SearchField";
import { isArray } from "lodash";
import { useAppDispatch } from "../app/hooks";
// import * as Sentry from '@sentry/react';
import { snacked } from "../features/snackMessage-slice";


export function useDefaultPath(nullDefault?: boolean): string {
  const {data: account} = useGetCurrentAccountQuery();
  return useMemo(() => {
    return getDefaultPathForAccountType(account?.type);
  }, [account?.type]);
}

/**
 * https://usehooks.com/usePrevious/
 * @param value 
 * @returns 
 */
export function usePrevious<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}


export function useEffectDebounced(effect: React.EffectCallback, period: number, deps: React.DependencyList) {
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timeout.current != null) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(effect, period);

    return () => {
      if (timeout.current != null) {
        clearTimeout(timeout.current);
      }
    }
    // eslint-disable-next-line
  }, [period, ...deps]);  
}


export function isAccountProfileFieldComplete(field: IProfileField, profile: IAccountProfile): boolean {
  if (!field.required) {
    return true;
  }

  if (profile == null) {
    return false;
  }
  
  switch (field.type) {
    // Ignore the value of boolean fields, we only want to know whether
    // or not the text fields are filled in
    case undefined:
    case 'text':
    case 'number':
    case 'phone': {
      const value = profile[field.key as keyof IAccountProfile];
      if (typeof value === 'string') {
        return value != null && value.length > 0;
      } else {
        return value != null;
      }
    }
    case 'select': {
      const value = profile[field.key as keyof IAccountProfile];
      if (field.multiselect) {
        return value != null && isArray(value) && value.length > 0;
      } else {
        return value != null && (value as string).length > 0;
      }
    }
    case 'boolean': {
      return true;
    }
    case 'radio': {
      const value = profile[field.key as keyof IAccountProfile];
      return value != null;
    }
    case 'row': {
      if (field.children != null && field.children.length > 0) {
        let ret = true;
        for (let child of field.children) {
          ret = ret && isAccountProfileFieldComplete(child, profile);
        }

        return ret;
      } else {
        return true;
      }
    }
  }
}


export function isAccountProfileComplete(account?: IAccount): boolean {
  if (account == null) {
    return false;
  }

  const sections = getProfileFields(account.type);
  return sections
    .flatMap(sec => sec.fields)
    .filter(field => field.required)
    .reduce((filled, field) => filled && isAccountProfileFieldComplete(field, account.profile), true);
}


export function useAccountProfileInsights(account?: IAccount): {isComplete?: boolean} {
  return useMemo(() => {
    if (account == null) {
      return {};
    }

    return {
      isComplete: isAccountProfileComplete(account),
    }
  }, [account]);
}


export function useDealParameter(params: {
  field: SearchField,
  dealInfo: Partial<IDealInfo>,
}) {
  const title = useMemo(() => {
    return typeof params.field.title === 'function' ? params.field.title(params.dealInfo) : params.field.title;
  }, [params.field, params.dealInfo]);

  const disabled = useMemo(() => {
    if (params.field.dependsOn == null) {
      return false;
    }

    return params.field.dependsOn.reduce((disabled, fieldId) => {
      return disabled && !Boolean(params.dealInfo[fieldId as keyof IDealInfo]);
    }, true);
  }, [params.field, params.dealInfo]);

  const dependingFieldTitles = useMemo(() => {
    if (params.field.dependsOn == null) {
      return undefined;
    }

    return params.field.dependsOn
      .map(fieldId => fields.find(f => f.id === fieldId))
      .map(otherField => typeof otherField!.title === 'function' ? otherField!.title(params.dealInfo) : otherField!.title)
      .join(', ');
  }, [params.field.dependsOn, params.dealInfo]);

  return {
    title,
    disabled,
    dependingFieldTitles,
  };
}

export function usePopoverContext<T = undefined>() {

  const [visible, setVisible] = useState(false);
  const [currentData, setCurrentData] = useState<T | undefined>();

  const showDialog = useCallback((data?: T) => {
    setCurrentData(data);
    setVisible(true);
  }, [setCurrentData, setVisible]);

  const hideDialog = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return {
    props: {
      open: visible,
      onClose: hideDialog,
    },
    showDialog,
    hideDialog,
    currentData,
  };
}

export function useErrorHandler() {
  const dispatch = useAppDispatch();

  const handleError = useCallback((params: {error?: any, message?: string}) => {
    if (params.error != null || params.message != null) {
      console.error(params.error ?? params.message);
    }

    if (params.message != null) {
      dispatch(snacked({
        message: params.message,
        severity: 'error',
      }));
    }
  }, [dispatch]);

  return {
    handleError,
  };
}

export function useLoanFeeStructure(amount?: number) {

  const tier1Start = 0;
  const tier1End = 5_000_000;
  const tier2Start = 5_000_000;
  const tier2End = 10_000_000;
  const tier3Start = 10_000_000;
  const tier3End = 50_000_000;
  const tier4Start = 50_000_000;
  const tier4End = 1_000_000_000;

  const tier1FeePerc = 0.01;
  const tier2FeePerc = 0.0075;
  const tier3FeePerc = 0.0050;
  const tier4FeePerc = 0.0025;

  const dollarFeeAmount = useMemo(() => {
    if (amount == null) {
      return undefined;
    }

    const t1Placement = amount > tier1End ? tier1End : amount * (amount > tier1Start ? 1 : 0);
    const t2Placement = amount > tier2End ? tier2End : amount * (amount > tier2Start ? 1 : 0);
    const t3Placement = amount > tier3End ? tier3End : amount * (amount > tier3Start ? 1 : 0);
    const t4Placement = amount > tier4End ? tier4End : amount * (amount > tier4Start ? 1 : 0);

    const t1Funded = t1Placement > 0 ? t1Placement - tier1Start : 0;
    const t2Funded = t2Placement > 0 ? t2Placement - tier2Start : 0;
    const t3Funded = t3Placement > 0 ? t3Placement - tier3Start : 0;
    const t4Funded = t4Placement > 0 ? t4Placement - tier4Start : 0;

    const t1Fee = t1Funded * tier1FeePerc;
    const t2Fee = t2Funded * tier2FeePerc;
    const t3Fee = t3Funded * tier3FeePerc;
    const t4Fee = t4Funded * tier4FeePerc;

    return t1Fee + t2Fee + t3Fee + t4Fee;
  }, [amount]);

  const blendedBasisPointFeeAmount = useMemo(() => {
    if (amount == null || dollarFeeAmount == null) {
      return undefined;
    }

    return (dollarFeeAmount / amount) * 10000.0;
  }, [amount, dollarFeeAmount]);

  const tiers = useMemo(() => {
    return [
      {
        name: 'Tier I',
        range: '$0 to $5 million',
        feeAmount: '100 basis points',
      },
      {
        name: 'Tier II',
        range: 'Each dollar above $5 million to $10 million',
        feeAmount: '75 basis points',
      },
      {
        name: 'Tier III',
        range: 'Each dollar above $10 million to $50 million',
        feeAmount: '50 basis points',
      },
      {
        name: 'Tier IV',
        range: 'Each dollar above $50 million',
        feeAmount: '25 basis points',
      }
    ];
  }, []);

  return {
    dollarFeeAmount,
    blendedBasisPointFeeAmount,
    tiers,
  };
}

export function useEquityFeeStructure(amount?: number, feeStructure?: AccountEquityFeeStructure, accountType?: AccountType) {

  const legacyTier1Start = 0;
  const legacyTier1End = 5_000_000;
  const legacyTier2Start = 5_000_000;
  const legacyTier2End = 1_000_000_000;
  const legacyTier1FeePerc = 0.02;
  const legacyTier2FeePerc = 0.01;

  const sponsorSimpleBBFeeAmount = 200;
  const brokerSimpleBBFeeAmount = 25;

  const dollarFeeAmount = useMemo(() => {
    if (amount == null) {
      return undefined;
    }

    switch (feeStructure) {
      case undefined:
      case 'legacy': {
        const t1Placement = amount > legacyTier1End ? legacyTier1End : amount * (amount > legacyTier1Start ? 1 : 0);
        const t2Placement = amount > legacyTier2End ? legacyTier2End : amount * (amount > legacyTier2Start ? 1 : 0);
    
        const t1Funded = t1Placement > 0 ? t1Placement - legacyTier1Start : 0;
        const t2Funded = t2Placement > 0 ? t2Placement - legacyTier2Start : 0;
    
        const t1Fee = t1Funded * legacyTier1FeePerc;
        const t2Fee = t2Funded * legacyTier2FeePerc;
    
        return t1Fee + t2Fee;
      }
      case 'simple': {
        switch (accountType) {
          case 'sponsor':
            return (sponsorSimpleBBFeeAmount / 10_000.0) * amount;
          case 'broker':
            return (brokerSimpleBBFeeAmount / 10_000.0) * amount;
          }
        break;
          
      }
      default:
        return undefined;
    }
  }, [accountType, amount, feeStructure]);

  const blendedBasisPointFeeAmount = useMemo(() => {
    switch (feeStructure) {
      case undefined:
      case 'legacy': {
        if (amount == null || dollarFeeAmount == null) {
          return undefined;
        }
    
        return (dollarFeeAmount / amount) * 10000.0;
      }
      case 'simple': {
        switch (accountType) {
          case 'sponsor':
            return sponsorSimpleBBFeeAmount;
          case 'broker':
            return brokerSimpleBBFeeAmount;
        }
        break;
      }
      default:
        return undefined;
    }
  }, [accountType, amount, dollarFeeAmount, feeStructure]);

  const tiers = useMemo(() => {
    switch (feeStructure) {
      case undefined:
      case 'legacy':
        return [
          {
            name: 'Tier I',
            range: '$0 to $5 million',
            feeAmount: '200 basis points',
          },
          {
            name: 'Tier II',
            range: 'Each dollar above $5 million',
            feeAmount: '100 basis points',
          },
        ];
      default:
        return [];
    }
  }, [feeStructure]);

  return {
    dollarFeeAmount,
    blendedBasisPointFeeAmount,
    tiers,
  };
}
