import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { IDeal } from '../../schemas';
import { useSearchLendersQuery, useUpdateDealMutation } from '../../features/deals-api';
import LenderSearchInfo from './LenderSearchDataGrids/LenderSearchDataGrid';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import { useSearchParams } from 'react-router-dom';
import LoadingButton from '../LoadingButton';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import * as Sentry from '@sentry/react';


interface Props {
  readonly deal?: IDeal;
}

export default function DealSearchResults({deal}: Props): JSX.Element {

  const dispatch = useAppDispatch();
  const [params, setParams] = useSearchParams()

  const tab = params.get('tab');

  const [filter, setFilter] = useState<'all' | 'favorites' | 'lender-outreach' | 'quote-matrix'>('all');

  const {data: account} = useGetCurrentAccountQuery();
  const {data: searchResults} = useSearchLendersQuery({ dealId: deal?._id!, filter: 'search' }, { skip: deal?._id == null });

  const [updateDeal, { isLoading: isUpdateDealLoading }] = useUpdateDealMutation();

  const quotedLenderCount = useMemo(() => {
    return deal?.contactedLenders?.reduce((sum, lender) => {
      if (lender.quoteOptions != null) {
        return sum + lender.quoteOptions.filter(o => o.quotes != null && o.quotes.length > 0).length;
      }

      return sum;
    }, 0);
  }, [deal?.contactedLenders]);

  const changeFilter = useCallback((newFilter: typeof filter) => {
    setParams({tab: newFilter});
  }, [setParams]);

  const moveToDiligenceClicked = useCallback(async () => {
    if (deal?._id == null) {
      return;
    }

    try {
      await updateDeal({
        dealId: deal._id,
        status: 'diligence',
      }).unwrap();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed moving deal to diligence',
        severity: 'error',
      }))
    }
  }, [deal?._id, dispatch, updateDeal]);

  useEffect(() => {
    if (tab != null) {
      setFilter(tab as typeof filter);
    }
  }, [tab]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '128px',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '12px',
        paddingBottom: '12px',
        justifyContent: 'space-between',
      }}>
        <Box sx={{
          display: 'flex',
          gap: '8px',
        }}>
          <Button
            variant='text'
            onClick={() => changeFilter('all')}>
            <Typography sx={{
              fontWeight: filter === 'all' ? 'bold' : '400',
              fontSize: '13px',
              lineHeight: '16px',
            }}>
              {`All (${searchResults?.length ?? 0})`}
            </Typography>
          </Button>

          {(account?.type === 'sponsor' || (account?.type === 'broker' && account.currentSubscription?.type === 'broker-pro')) &&
            <>
              <Button
                variant='text'
                onClick={() => changeFilter('favorites')}>
                <Typography sx={{
                  fontWeight: filter === 'favorites' ? 'bold' : '400',
                  fontSize: '13px',
                  lineHeight: '16px',
                }}>
                  {`Favorites (${deal?.favoriteLenders?.length ?? 0})`}
                </Typography>
              </Button>

              <Button
                variant='text'
                onClick={() => changeFilter('lender-outreach')}>
                <Typography sx={{
                  fontWeight: filter === 'lender-outreach' ? 'bold' : '400',
                  fontSize: '13px',
                  lineHeight: '16px',
                }}>
                  {`Lender Outreach (${deal?.contactedLenders?.length ?? 0})`}
                </Typography>
              </Button>

              <Button
                variant='text'
                onClick={() => changeFilter('quote-matrix')}>
                <Typography sx={{
                  fontWeight: filter === 'quote-matrix' ? 'bold' : '400',
                  fontSize: '13px',
                  lineHeight: '16px',
                }}>
                  {`Quote Matrix (${quotedLenderCount ?? 0})`}
                </Typography>
              </Button>
            </>
          }
        </Box>

        {deal?.accountId === account?._id
          && deal?.whiteGlove?.status !== 'enabled'
          && (account?.type !== 'broker' || (account.currentSubscription?.isActive && account.currentSubscription.type === 'broker-pro')) &&
          <LoadingButton
            variant='text'
            loading={isUpdateDealLoading}
            onClick={moveToDiligenceClicked}>
            <Typography sx={{
              fontWeight: 'bold',
              fontSize: '13px',
              lineHeight: '16px',
            }}>
              {'Move to Diligence'}
            </Typography>
          </LoadingButton>
        }
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: filter === 'quote-matrix' ? undefined : 'calc(100vh - 200px)',
      }}>
        <LenderSearchInfo
          deal={deal}
          filter={filter} />
      </Box>
    </Box>
  );
}
